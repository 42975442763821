import CustomButton from "components/CustomButton";
import React, { useState } from "react";
import copyAsLink from "assets/images/svg/copy-link-icon.svg";
import closeIcon from "assets/images/svg/close-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TestCreateSuccess = ({
  handleCancel,
  goToTest,
  testType,
  origin,
  testAliasName,
}) => {
  const test_link = `${origin}/competitions/${testAliasName}`;
  const [copied, setCopied] = useState(false);
  const copyClickHandler = () => {
    setCopied(true);
  };

  return (
    <div className="test-success-card">
      <div className="test-card-title-block">
        <span className="success-card-title">Test Created Successfully</span>
        <span
          style={{ fontSize: "25px", cursor: "pointer" }}
          onClick={handleCancel}
        >
          <img src={closeIcon} alt="close-icon" />
        </span>
      </div>
      <div className="success-card-description">
        {testType
          ? "Your test has been created successfully. You can share the fun with your friends by creating private test."
          : "Share with your friends and have fun by competing each other."}
      </div>
      {testType && (
        <div className="test-link-container">
          <p>{`${test_link.slice(0, 50)}...`}</p>
          <CopyToClipboard
            text={`${origin}/competitions/${testAliasName}`}
            onCopy={copyClickHandler}
          >
            <span className="copy-img">
              <img
                title={"copy to clipboard"}
                style={{ cursor: "pointer" }}
                src={copyAsLink}
                alt="private-link"
              />
            </span>
          </CopyToClipboard>
          <span className="test-link-line"></span>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CustomButton
          buttonClick={() => goToTest(testAliasName)}
          buttonText={"Go to test"}
          style={{
            width: "236px",
            height: "50px",
            background: "linear-gradient(#d8f5a2, #3e9c75)",
            borderRadius: "46px",
            fontFamily: "Nunito",
            fontStyle: "normal",
            fontWeight: 800,
            fontSize: "22px",
            lineHeight: "30px",
            textAlign: "center",
            letterSpacing: "0.02em",
            color: "#FFFFFF",
            border: "none",
            cursor: "pointer",
          }}
        />
        <span style={{ fontSize: "16px" }}>
          {copied && "Link copied to clipboard"}
        </span>
      </div>
    </div>
  );
};

export default TestCreateSuccess;
