import React, { useEffect } from 'react';

export const ProgressBar = (data) => {
    useEffect(() => {
        const doc = document.documentElement.style;
        const css_applier = document.getElementById(data.title);
        if (data.title === "Speed") {
            doc.setProperty('--speed_percentage', `${data.percentage}turn` || "0turn");
            return css_applier?.classList.add("progress-fill-speed")
        }
        else if (data.title === "Accuarcy") {
            doc.setProperty('--accuarcy_percentage', `${data.percentage}turn` || "0turn");
            return css_applier?.classList.add("progress-fill-accuarcy")
        } else {
            doc.setProperty('--typos_percentage', `${data.percentage}turn` || "0turn");
            return css_applier?.classList.add("progress-fill-typos")
        }
    })
    return (
        <div className='progress-container'>
            <div className='progress-inner-container'>
                <div id={data.title} className='progress-fill' style={{ background: `${data.style}` }}></div>
                <div className='progress-cover'>
                    <div style={{ paddingTop: "10px" }}>
                        <p style={{ paddingBottom: "14%", fontSize: "13px", fontWeight: "400" }}>{data.title}</p>
                        <p className='progress-des'>{data.value}</p>
                    </div>
                </div>
            </div>
        </div>
    )
};
