import { Header } from "components/Header";
import React from "react";
import "assets/css/Login.css";
import OneTapLogin from "./OneTapLogin";

const LoginDialog = () => (WrappedComponent) => {
  const HOC = (props) => {
    const childRef = React.useRef();

    return (
      <div
        className="login-background"
      >
        <OneTapLogin />
        <div id="container">
          <div
            className="login-container"
            style={{
              height: "100%",
            }}
          >
            <Header
              style={{ position: "relative" }}
              login={true}
              logoRedirect={"/"}
            />
            <div className="login-inner-container"
              style={{
                width: "80%",
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
                gap: "30px",
              }}
            >
              <div className="login-description" style={{ width: "60%" }}>
                <p className="login-font-heading">
                  Login to track your typing masterclass
                </p>
                <p className="login-font-description">
                  You are one step away from tracking the progress of your
                  typing journey.
                </p>
                <p
                  className="login-font-description"
                  style={{ fontStyle: "italic", width: "85%" }}
                >
                  “Save your work, you may never know when the light will go
                  off.”
                </p>
                <p
                  className="login-font-description"
                  style={{ textAlign: "right", paddingRight: 25 }}
                >
                  - Lailah Gifty
                </p>
              </div>
              <WrappedComponent ref={childRef} {...{ ...props }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return HOC;
};

export default LoginDialog;
