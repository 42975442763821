import { Header } from "components/Header";
import React, { Component } from "react";
import "assets/css/test/CreateForm.css";
import CustomButton from "components/CustomButton";
import moment from "moment";
import { createPersonalTest } from "api/testing";
import { getCookie, deleteAllCookie } from "helpers";
import TestCreateSuccess from "./testCreateSuccess";
import { TiArrowLeftThick } from "react-icons/ti";
import ReactSelect from "components/ReactSelect.js";
class CreateTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testType: true,
      timeLimit: { value: "minute_1", label: "1 Minute" },
      expiryDate: moment()
        .add(7, "d")
        .format("YYYY-MM-DD hh:mm:ss"),
      content: "",
      name: "",
      success: false,
      test_id: "",
      test_alias_name: "",
      errorMessage: "",
      buttonDisable: false,
      mode: { value: "universal", label: "Public" },
      expiryLimit: { value: "1 week", label: "1 Week" },
    };
    this.timeline = [
      { value: "minute_1", label: "1 Minute" },
      { value: "minute_2", label: "2 Minute" },
      { value: "minute_3", label: "3 Minute" },
      { value: "minute_5", label: "5 Minute" },
      { value: "minute_10", label: "10 Minute" }
    ];

    this.test_type = [
      { value: "universal", label: "Public" },
      { value: "personal", label: "Private" }
    ];

    this.expiry_list = [
      { value: "1 day", label: "1 Day" },
      { value: "2 days", label: "2 Days" },
      { value: "1 week", label: "1 Week" },
      { value: "30 days", label: "30 Days" },
      { value: "3 months", label: "3 Months" },
    ]
  }

  selectChange = (object, select_type) => {
    if (select_type === "expiryLimit") {
      let expiryPeriodNumber = object.value.split(" ")[0];
      let expiryPeriod = object.value.split(" ")[1];
      if (expiryPeriod.includes("day")) {
        this.setState({
          expiryDate: moment()
            .add(expiryPeriodNumber, "d")
            .format("YYYY-MM-DD hh:mm:ss"),
        });
      } else if (expiryPeriod.includes("week")) {
        let convertWeekToDays = expiryPeriodNumber * 7;
        this.setState({
          expiryDate: moment()
            .add(convertWeekToDays, "d")
            .format("YYYY-MM-DD hh:mm:ss"),
        });
      } else if (expiryPeriod.includes("month")) {
        this.setState({
          expiryDate: moment()
            .add(expiryPeriodNumber, "m")
            .format("YYYY-MM-DD hh:mm:ss"),
        });
      }
    }
    this.setState({
      [select_type]: object
    });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: e && false,
    });
  };

  handleSubmit = () => {
    const auth_token = getCookie("auth_token");
    const { timeLimit, content, expiryDate, name, mode } = this.state;
    const requestBody = {
      name,
      content,
      time_line: timeLimit.value,
      mode: mode.value,
      expired_at: expiryDate,
    };
    this.setState({ buttonDisable: true })
    if (!name || !content || !timeLimit || !expiryDate) {
      this.setState({ errorMessage: "Check all the above fields are filled" })
      return
    }
    createPersonalTest(auth_token, requestBody)
      .then(({ data }) => {
        this.setState({
          success: true,
          test_alias_name: data,
          buttonDisable: true
        });
      })
      .catch((err) => {
        if (err[0] === 404) {
          deleteAllCookie();
          window.location.href = '/login'
        } else {
          this.setState({
            errorMessage: (err[1]?.data && err[1]?.data[0]) || err[1].message,
            buttonDisable: false
          });
        }
      });
  };

  handleCancel = () => {
    this.setState({
      success: false,
    });
    window.location.href = '/competitions'
  };

  goToTest = (test_id) => {
    this.props.history.push({
      pathname: `/competitions/${test_id}`,
    });
  };

  render() {
    const {
      timeLimit,
      success,
      testType,
      test_alias_name,
      errorMessage,
      buttonDisable,
      expiryLimit,
    } = this.state;
    const { timeline, expiry_list, selectChange } = this;
    return (
      <div className="background-image">
        <div
          style={{
            width: "100%",
            maxWidth: "1440px",
            margin: "auto",
          }}
        >
          <Header logoRedirect={"/competitions"} />
          <div className="test-form-container">
            {success && (
              <TestCreateSuccess
                goToTest={this.goToTest}
                handleCancel={this.handleCancel}
                testType={testType}
                origin={window.location.origin}
                testAliasName={test_alias_name}
              />
            )}
            <span
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
              onClick={() => this.props.history.push("/competitions")}
            >
              <span style={{ marginRight: "5px" }}>
                <TiArrowLeftThick style={{ fontSize: "25px" }} />
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  fontFamily: "Nunito",
                }}
              >
                Back
              </span>
            </span>
            <div className="test-form-title">Create New Competition</div>
            <div className="test-form-description">
              Typing is skill which can be learned only by testing yourself by
              trying it on a daily basis.
            </div>
            <div className="form-inputs-container">
              <div className="create-first-input-section">
                <input
                  name="name"
                  style={{
                    flex: "3",
                  }}
                  type="text"
                  placeholder="Title"
                  autoComplete="off"
                  onChange={this.handleChange}
                />

              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ flex: "1" }} >
                  <label style={{fontWeight: 600, paddingLeft: "5px"}}>Select Expiry Time</label>
                  <ReactSelect options={expiry_list} placeholder={"Select Expiry Time"} type={"expiryLimit"} value={expiryLimit} onHandleChange={selectChange} />
                </div>
                <div style={{ flex: "1" }} >
                  {/* <ReactSelect options={test_type} placeholder={"Select Test Type"} type={"mode"} value={mode} onHandleChange={selectChange} /> */}
                </div>
                <div style={{ flex: "1" }}>
                  <label style={{fontWeight: 600, paddingLeft: "5px"}}>Select Time Line</label>
                  <ReactSelect options={timeline} placeholder={"Select Time Line"} type={"timeLimit"} value={timeLimit} onHandleChange={selectChange} />
                </div>
              </div>
              <div className="create-textarea-container">
                <textarea
                  name="content"
                  placeholder="Type Your Content"
                  style={{
                    background: "#FFF",
                    borderRadius: "8px",
                    fontFamily: "Source Sans Pro",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "20px",
                    lineHeight: "32px",
                    letterSpacing: "0.02em",
                    color: "#000000",
                    padding: "15px",
                    resize: "none",
                  }}
                  rows="6"
                  onChange={this.handleChange}
                />
                {errorMessage && (
                  <p
                    style={{
                      fontSize: "20px",
                      color: "#635198",
                      marginTop: "10px",
                    }}
                  >
                    {errorMessage}
                  </p>
                )}
              </div>
              <div className="create-submit">
                <CustomButton
                  buttonText={"SUBMIT"}
                  buttonClick={this.handleSubmit}
                  buttonDisable={buttonDisable}
                  style={{
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 800,
                    fontSize: "22px",
                    lineHeight: "30px",
                    letterSpacing: "0.02em",
                    color: "#FFFFFF",
                    width: "280px",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "45px",
                    background:
                      "linear-gradient(#d8f5a2, #3e9c75)",
                    padding: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTest;
