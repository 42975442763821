import React, { Component } from "react";
import "assets/css/lesson/Home.css";
import "assets/css/lesson/Lesson.css";
import "assets/css/lesson/LeftPosition.css";
import "assets/css/lesson/RightPosition.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { Header } from "components/Header";
import { getLessonData } from "api/home";
import CorrectKey from "assets/audios/correct-key.mp3";
import WrongKey from "assets/audios/incorrect-key.mp3";
import BackspaceSound from "assets/audios/backspace-sound.mp3";
import {
  getCookie,
  preloadLeftImages,
  preloadRightImages,
  setCookie,
  generateUUID,
  RandomImage,
  LeftSpecialKey,
  RightSpecialKey,
  RightArrayList,
  LeftArrayList,
  allowedKeys,
} from "helpers";
import { SuccessPage } from "./successPage";
import { WordContent, LetterContent } from "./contentBlock";
import { createLesson } from "api/lessons";
import { withRouter } from "react-router-dom";
import { TiHome } from "react-icons/ti";
import OneTapLogin from "views/authentication/OneTapLogin";
import { getTestData, submitTest } from "api/testing";
import TestSuccessPage from "../../tests/testSuccessPage";

class LessonPage extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.test_timer = null;
    this.wpmTimer = null;
    this.correctAudio = new Audio(CorrectKey);
    this.wrongAudio = new Audio(WrongKey);
    this.backSpaceAudio = new Audio(BackspaceSound);
    this.RandomImage = RandomImage();
    this.state = {
      activeIndex: 0,
      rightChar: 0,
      wrongChar: 0,
      duration: 0,
      contentStartIndex: 0,
      content: this.props?.location?.state?.lesson?.content || "",
      contentArray: [],
      coloring: "",
      accuracy: 0,
      wpm: 0,
      successPage: false,
      leftArray: LeftArrayList(),
      rightArray: RightArrayList(),
      leftNextKey: "rest",
      rightNextKey: "rest",
      lessonName: "",
      layoutName: "default",
      next_lesson_alias_name: "",
      lesson_id: "",
      leftImages: null,
      rightImages: null,
      full_word_length: [],
      counting_length: 0,
      hideline: 0,
      backtrace: false,
      type: "chapters",
      actual_time: "",
      test_timeline: ""
    };
  }

  componentDidMount() {
    let route = window.location.pathname.split("/")[1]
    if (route === "competitions") {
      this.fetchTestData();
    } else {
      this.fetchLessonData();
    }
    this.myinput = document.getElementById("myinput");
    if (this.myinput) {
      this.myinput.focus();
    }
    this.loadImage();
    if (!getCookie("user_id")) {
      setCookie("user_id", generateUUID());
    }
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.test_timer) {
      clearTimeout(this.test_timer);
      clearTimeout(this.wpmTimer);
      this.test_timer = null;
      this.wpmTimer = null;
    }
  };

  loadImage = () => {
    const leftImages = this.state.leftArray?.map((character) =>
      preloadLeftImages(character)
    );
    const rightImages = this.state.rightArray?.map((character) =>
      preloadRightImages(character)
    );
    this.setState({
      leftImages,
      rightImages,
    });
  };

  fetchTestData = () => {
    const { leftArray, rightArray } = this.state;
    const auth_token = getCookie("auth_token");
    getTestData(auth_token, this.props.match.params.id).then(({ data }) => {
      let finalArray = [];
      let split_content = data.content.split(" ");
      let temp = [];
      let count = 0;
      split_content.forEach((word, i) => {
        count += word.length + 1;
        temp.push(word);
        if (
          count > 56 ||
          (count > 52 && split_content[i + 1]?.length > 8) ||
          i === split_content.length - 1
        ) {
          finalArray.push(temp);
          temp = [];
          count = 0;
        }
      });
      let full_word_length = [];
      for (let i = 0; i < finalArray.length; i++) {
        full_word_length.push(finalArray[i].join(" ").length + 1);
      }
      this.setState({
        contentArray: finalArray,
        test_id: data.id,
        content: data.content,
        section: "word",
        actual_time: parseInt(data?.time_line?.split("_")?.[1]) * 60,
        test_timeline: parseInt(data?.time_line?.split("_")?.[1]) * 60,
        full_word_length: full_word_length,
        backtrace: true,
        counting_length: full_word_length[0],
        leftNextKey: leftArray?.includes(data?.content[0])
          ? data.content[0] : (LeftSpecialKey(data.content[0]) || "rest"),
        rightNextKey: rightArray?.includes(data.content[0])
          ? data.content[0] : (RightSpecialKey(data.content[0]) || "rest"),
        type: "competitions",
        error_pass: true,
        lessonName: data.name
      });
    }).catch((err) => {
      console.log("error", err);
    });
  };

  createUserTestData = (actual_time, test_timeline, wpm, accuracy) => {
    const auth_token = getCookie("auth_token");
    const { test_id } = this.state;
    const requestParams = {
      accuracy: accuracy > 0 ? accuracy : 1,
      speed: wpm || 1,
      time: actual_time - test_timeline,
      user_id: getCookie("user_id"),
    };
    if (requestParams.accuracy >= 60 ) {
      submitTest(auth_token, test_id, requestParams)
      .then(({ data }) => {
        clearTimeout(this.test_timer);
        clearTimeout(this.wpmTimer);
        this.setState({
          successPage: true,
          wpm: data.speed
        });
      })
      .catch((e) => {
        console.log(e);
      })
    }
    else {
      clearTimeout(this.test_timer);
      clearTimeout(this.wpmTimer);
      this.setState({
        successPage: true,
        wpm: requestParams.speed
      })
    }
  };

  fetchLessonData = () => {
    const { leftArray, rightArray } = this.state;
    if (!this.state.content) {
      const { chapter_id, id } = this.props.match.params;
      getLessonData(chapter_id, id)
        .then(({ data }) => {
          let finalArray = [];

          if (data.section === "word") {
            let split_content = data.content.split(" ");
            let temp = [];
            let count = 0;
            split_content.forEach((word, i) => {
              count += word.length + 1;
              temp.push(word);
              if (
                count > 56 ||
                (count > 52 &&
                  split_content[i + 1] &&
                  split_content[i + 1].length > 8) ||
                i === split_content.length - 1
              ) {
                finalArray.push(temp);
                temp = [];
                count = 0;
              }
            });
          }
          let full_word_length = [];
          for (let i = 0; i < finalArray.length; i++) {
            full_word_length.push(finalArray[i].join(" ").length + 1);
          }

          this.setState({
            contentArray: finalArray,
            content: data.content,
            lessonName: data.name,
            section: data.section,
            lesson_id: data.id,
            full_word_length: full_word_length,
            counting_length: full_word_length[0],
            next_lesson_alias_name: data.next_lesson?.alias_name,
            error_pass: data.error_pass,
            backtrace: data.backtrace,
            leftNextKey: leftArray?.includes(data?.content[0])
              ? data.content[0] : (LeftSpecialKey(data.content[0]) || "rest"),
            rightNextKey: rightArray?.includes(data.content[0])
              ? data.content[0] : (RightSpecialKey(data.content[0]) || "rest"),
            total_lessons: data.total_lessons
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  incrementDuration = () => {
    const {
      rightChar,
      wrongChar,
      duration, successPage
    } = this.state;

    this.timer = setTimeout(() => {
      if (!successPage) {
        this.setState({ duration: duration + 1 });
        this.incrementDuration();
      }
    }, 1000);
    this.wpmTimer = setTimeout(
      () =>
        this.calculateWPM(rightChar, wrongChar, duration, 0),
      2000
    );
  };

  calculateWPM = (rightChar, wrongChar, actual_time, test_timeline) => {
    let totalChars = rightChar + wrongChar;
    let calculateTime = actual_time - test_timeline;
    let response = totalChars / 5 / (calculateTime / 60);
    const { type } = this.state;
    if (!isNaN(response)) {
      let wpm = Math.floor(response);
      if (test_timeline !== 0 || type === "chapters") {
        this.setState(
          {
            wpm,
          });
      }
    }
  };

  decrementDuration = () => {
    const {
      successPage,
      wpm,
      accuracy,
      rightChar,
      wrongChar,
      actual_time,
      test_timeline,
    } = this.state;
    if (test_timeline !== 0) {
      this.test_timer = setTimeout(() => {
        this.setState({ test_timeline: test_timeline - 1 });
        if (!successPage) {
          this.decrementDuration();
        }
      }, 1000);
      this.wpmTimer = setTimeout(
        () =>
          this.calculateWPM(rightChar, wrongChar, actual_time, test_timeline),
        2000
      );
    } else {
      clearTimeout(this.test_timer);
      clearTimeout(this.wpmTimer);
      this.createUserTestData(
        actual_time,
        test_timeline,
        wpm,
        accuracy
      )
    }
  };

  createUserData = () => {
    const { accuracy, duration, content, lesson_id, wpm } = this.state;
    const user_id = getCookie("user_id") || generateUUID();
    // TODO: Add WPM Calculations
    const requestParams = {
      user_id,
      accuracy,
      stars: accuracy > 95 ? 3 : accuracy > 85 ? 2 : 1,
      speed: wpm,
      time: duration,
      characters: content.length,
    };
    createLesson(lesson_id, requestParams)
      .then((res) => {
        console.log(res, "success------>");
        // this.props.history.push
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  playAudio = (type) => {
    let clone = null;
    if (type === "correct") {
      clone = this.correctAudio.cloneNode();
    } else if (type === "wrong") {
      clone = this.wrongAudio.cloneNode();
    } else {
      clone = this.backSpaceAudio.cloneNode();
    }
    clone.play();
  };

  onKeyPress = (button, e) => {
    if (e.keyCode === 32 || e.keyCode === 9) {
      e.preventDefault();
    }

    if (
      this.state.successPage ||
      !allowedKeys.includes(button) ||
      e.ctrlKey ||
      e.altKey ||
      e.metaKey
    ) {
      return;
    }

    let {
      content,
      activeIndex,
      contentStartIndex,
      coloring,
      rightChar,
      wrongChar,
      leftArray,
      rightArray,
      full_word_length,
      section,
      counting_length,
      hideline,
      error_pass,
      backtrace,
      type,
      test_timeline
    } = this.state;
    if (content.length <= 0 || (!backtrace && e.keyCode === 8)) {
      return;
    }

    if (backtrace && e.keyCode === 8) {
      if (coloring.length === 0) {
        this.playAudio("wrong");
        counting_length = full_word_length[hideline]
        this.setState({ counting_length })
        return
      }
      coloring = coloring.slice(0, -1)
      activeIndex = activeIndex - 1
      let check_length = counting_length - full_word_length[hideline]
      if (coloring.length + 1 === check_length) {
        counting_length -= full_word_length[hideline];
        hideline -= 1;
      }
      this.setState({
        coloring, activeIndex, counting_length, hideline, leftNextKey: leftArray.includes(content[activeIndex])
          ? content[activeIndex]
          : (LeftSpecialKey(content[activeIndex]) || "rest"),
        rightNextKey: rightArray.includes(content[activeIndex])
          ? content[activeIndex]
          : (RightSpecialKey(content[activeIndex]) || "rest")
      })

      this.playAudio("backspace");
      return
    }

    let completed = content.length <= coloring.length + 1;

    if (!error_pass && coloring[coloring.length - 1] === "0" && content[activeIndex] !== button) {
      this.playAudio("wrong");
      return false
    }

    if (this.timer === null && type === "chapters") {
      this.incrementDuration(); // Start duration counter
    }

    if (coloring.length + 1 === counting_length) {
      counting_length += full_word_length[hideline + 1];
      hideline += 1;
    }
    const totalChars = rightChar + wrongChar + 1;
    let accuracy = 0;
    if (content[activeIndex] === button) {
      accuracy = Math.floor(((rightChar + 1) / totalChars) * 100);
    } else {
      accuracy = Math.floor((rightChar / totalChars) * 100);
    }
    let newContentIndex =
      activeIndex > 4 && content.length - contentStartIndex > 12
        ? contentStartIndex + 1
        : contentStartIndex;

    if (completed && test_timeline !== 0 && accuracy === 0 && type === "competitions") {
      completed = false;
      window.location.reload();
    }

    if (completed && type === "competitions") {
      clearTimeout(this.test_timer);
      clearTimeout(this.wpmTimer);
    }

    if (this.test_timer === null && test_timeline !== 0 && type === "competitions") {
      this.decrementDuration(); // Start duration counter
    }

    if (section === "word") {
      if (coloring.length + 1 === counting_length) {
        counting_length += full_word_length[hideline + 1];
        hideline += 1;
      }
    }

    if (content[activeIndex] === button) {
      this.playAudio("correct");
      accuracy = Math.floor(((rightChar + 1) / totalChars) * 100);
      this.setState(
        {
          contentStartIndex: newContentIndex,
          rightChar: rightChar + 1,
          coloring: coloring.concat("1"),
          leftNextKey: leftArray.includes(content[activeIndex + 1])
            ? content[activeIndex + 1]
            : (LeftSpecialKey(content[activeIndex + 1]) || "rest"),
          rightNextKey: rightArray.includes(content[activeIndex + 1])
            ? content[activeIndex + 1]
            : (RightSpecialKey(content[activeIndex + 1]) || "rest"),
          activeIndex: activeIndex + 1,
          successPage: type === "competitions" ? false : completed,
          accuracy: test_timeline !== 0 ? accuracy : this.state.accuracy,
          counting_length,
          hideline,
        },
        () => {
          if (completed) {
            if (type === "competitions") {
              this.createUserTestData(
                this.state.actual_time,
                this.state.test_timeline,
                this.state.wpm,
                this.state.accuracy
              );
            } else {
              this.createUserData();
            }
          }
        }
      );
    } else {
      this.playAudio("wrong");
      accuracy = Math.floor((rightChar / totalChars) * 100);
      this.setState(
        {
          contentStartIndex: newContentIndex,
          wrongChar: wrongChar + 1,
          coloring: coloring.concat("0"),
          activeIndex: activeIndex + 1,
          successPage: type === "competitions" ? false : completed,
          leftNextKey: leftArray.includes(content[activeIndex + 1])
            ? content[activeIndex + 1]
            : "rest",
          rightNextKey: rightArray.includes(content[activeIndex + 1])
            ? content[activeIndex + 1]
            : content[activeIndex + 1] === ";"
              ? "semicolon"
              : content[activeIndex + 1] === " "
                ? "space"
                : "rest",
          accuracy: test_timeline !== 0 ? accuracy : this.state.accuracy,
          counting_length,
          hideline,
        },
        () => {
          if (completed) {
            if (type === "competitions") {
              this.createUserTestData(
                this.state.actual_time,
                this.state.test_timeline,
                this.state.wpm,
                this.state.accuracy
              );
            } else {
              this.createUserData();
            }
          }
        }
      );
    }
  };

  handleRetry = () => {
    window.location.reload();
  };

  render() {
    const {
      content,
      wrongChar,
      rightChar,
      accuracy,
      duration,
      successPage,
      wpm,
      leftNextKey,
      rightNextKey,
      coloring,
      contentStartIndex,
      section,
      contentArray,
      layoutName,
      counting_length,
      next_lesson_alias_name,
      total_lessons,
      hideline,
      actual_time, test_timeline, test_id, type
    } = this.state;
    const { chapter_id } = this.props.match.params;

    if (successPage
      && type === "competitions"
    ) {
      return (
        <TestSuccessPage
          testId={test_id}
          wpm={wpm > 0 ? wpm : 1}
          duration={actual_time - test_timeline}
          accuracy={accuracy}
        />
      );
    }

    if (successPage && type === "chapters") {
      return (
        <SuccessPage
          wpm={wpm}
          accuracy={accuracy}
          wrongChar={wrongChar}
          handleRetry={this.handleRetry}
          chapter_id={chapter_id}
          next_lesson={next_lesson_alias_name}
          current_lesson={this.props.match.params.id}
          rightChar={rightChar}
          total_lessons={total_lessons}
        />
      );
    }
    return (
      <div className="lesson-bg" onClick={() => this.myinput.focus()} style={{ backgroundImage: `url(${this.RandomImage})` }}>
        <div id="container">
          <Header logoRedirect={type === "competitions" ? "/competitions" : "/"} />
          <OneTapLogin />

          <div className="text-content-container">
            <div className="text-stats-container">
              <span style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => type === "chapters" ? this.props.history.push("/") : this.props.history.push("/competitions")}
                >
                  <TiHome title={"Go to home"} style={{ fontSize: "25px" }} />
                </span>
                <p
                  className="lesson-name-block"
                  style={{ display: "inline-block" }}
                >
                  {this.state.lessonName.length > 35 ? `${this.state.lessonName.slice(0, 35)}...` : this.state.lessonName}
                </p>
              </span>

              <div className="stats-container">
                <input
                  name="myinput"
                  id="myinput"
                  style={{
                    width: 0,
                    height: 0,
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    opacity: 0,
                  }}
                  // onKeyPress={(e) => this.onKeyPress(e.key, e)}
                  onKeyDown={(e) => this.onKeyPress(e.key, e)}
                  autoComplete="off"
                />
                {<React.Fragment>
                  <p className="lesson-typos">
                    Typos <span>{wrongChar}</span>
                  </p>
                  <p className="lesson-typos">
                    WPM <span>{wpm}</span>
                  </p>
                  <p className="lesson-wpm">
                    Time <span>{type === "chapters" ? duration : test_timeline} sec</span>
                  </p>
                  <p className="lesson-wpm">
                    Accuracy <span>{accuracy} %</span>
                  </p>
                </React.Fragment>
                }
              </div>
            </div>
            <div
              id="text-content-inner-container"
              className="text-content-inner-container"
            >
              {section === "word" ? (
                <div id="textContent" className="words-container">
                  <WordContent
                    content={contentArray}
                    arrayStartIndex={0}
                    coloring={coloring}
                    counting_length={counting_length}
                    hideline={hideline}
                  />
                </div>
              ) : (
                <div id="textContent" className="characters-container">
                  <LetterContent
                    content={content}
                    contentStartIndex={contentStartIndex}
                    coloring={coloring}
                  />
                </div>
              )}
            </div>
            {content.length <= 0 && (
              <div
                style={{
                  position: "fixed",
                  paddingTop: "25px",
                  fontFamily: "Nunito",
                }}
              >
                Loading...
              </div>
            )}
          </div>
          <div className="keyboard-container">
            <div className="keyboard-block">
              <Keyboard
                layoutName={layoutName}
                // onKeyPress={(button, e) => this.onKeyPress(button, e)}
                physicalKeyboardHighlight={true}
                physicalKeyboardHighlightPress={true}
                preventMouseDownDefault={true}
                physicalKeyboardHighlightTextColor="#fff"
                physicalKeyboardHighlightBgColor={"#3295db"}
              />
            </div>
            <div className="overlay-block">
              <div
                className={`left-rest-to-${leftNextKey.toLowerCase()}-container`}
              >
                <img
                  className={`left-${leftNextKey.toLowerCase()}`}
                  src={`/fingers/left-on-${leftNextKey.toLowerCase()}.png`}
                  alt=""
                />
              </div>
              <div
                className={`right-rest-to-${rightNextKey.toLowerCase()}-container`}
              >
                <img
                  className={`right-${rightNextKey.toLowerCase()}`}
                  src={`/fingers/right-on-${rightNextKey.toLowerCase()}.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LessonPage);
