import React from "react";
import Modal from "react-awesome-modal";
import { MdOutlineClose } from "react-icons/md";
import { BsFillBookmarkFill } from "react-icons/bs";
import "assets/css/lesson/Home.css";

export const ChapterCard = ({ overview_details, close, pageScroll }) => {
  let extractedData = [];
  let x = overview_details?.forEach((level) => {
    Object.keys(level.chapter_groups).length > 0 &&
      Object.keys(level.chapter_groups).forEach((group) => {
        Object.keys(level.chapter_groups[group].chapters).forEach((chapter) => {
          extractedData.push(
            level.chapter_groups[group].chapters[chapter].name
          );
        });
      });
  });
  console.log(x); // dummy log to avoid lint
  return (
    <Modal
      visible={true}
      width="40%"
      height="80%"
      effect="fadeInUp"
      onClickAway={close}
    >
      <div style={{ padding: "25px", overflow: "scroll", height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
            borderBottom: "2px solid #ff0000",
          }}
        >
          <h2>
            <b>Chapters Overview</b>
          </h2>
          <p style={{ fontSize: 20, cursor: "pointer" }} onClick={close}>
            <MdOutlineClose />
          </p>
        </div>
        <div style={{ marginTop: 20 }}>
          {extractedData.map((value, i) => {
            return (
              <p
                key={i}
                className="chapter-card-p"
                onClick={() => {
                  pageScroll(value);
                }}
              >
                <span style={{ marginRight: "7px" }}>
                  <BsFillBookmarkFill />
                </span>
                <span>{value}</span>
              </p>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
