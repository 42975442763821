import React from "react";

const Time = ({ data, styles }) => {
  return (
    <>
      {Object.keys(data.time).map((item, i) => {
        let userReachCount = Math.floor(data.time[item]?.["user_reach_count"] / 60);
        let actualReachCount = data.time[item]["reach_count"] / 60;
        let enabledImage = data.time[item]["enable_image"];
        let disabledImage = data.time[item]["disable_image"];
        let timeInSeconds = data["users_time"] || 0;
        let user_time = Math.floor(timeInSeconds / 60);
        let width = userReachCount ? "100%" : "0%"
        return (
          <>
            <div
              key={i}
              style={
                user_time >= actualReachCount
                  ? styles.enableCard
                  : styles.disableCard
              }
            >
              <div style={styles.insideCard}>
                <img
                  src={
                    user_time >= actualReachCount ? enabledImage : disabledImage
                  }
                  style={styles.cardImg}
                  alt="icon"
                />
                <div
                  style={{
                    backgroundColor: "#cfcfcf",
                    borderRadius: "3px",
                  }}
                >
                  <div className="progress">
                    <div
                      className="progress-inloader"
                      style={{
                        width: width,
                        height: "1.5rem",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "10rem",
                          gap: 5,
                        }}
                      >
                        <span>
                          {user_time <= actualReachCount
                            ? user_time
                            : actualReachCount}
                        </span>
                        <span>/</span>
                        <span>{actualReachCount}</span>
                        <span>Minutes Spent</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default Time;
