import { greetList } from "config";
import { colorList } from "config";
import moment from "moment";

function headers() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

function getCookie(name) {
  let cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return cookie && cookie[2];
}

function setCookie(name, value, days = 30, hours = 0) {
  let date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + "=" + value + ";path=/;expires=" + date.toGMTString();
  } else {
    document.cookie = name + "=" + value + ";path=/";
  }
}

function dateAndTimeFormat(time) {
  let date = moment(time).format("DD MMM,  HH:MM A");
  return date;
}

function dateFormat(time) {
  let date = moment(time).format("DD MMM");
  return date;
}

function titleize(str) {
  if (!str) {
    return str;
  }

  return str
    .split(" ")
    .map(function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ")
    .split("_")
    .map(function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    })
    .join(" ");
}

function minutesFormat(minutes) {
  const [word, digits] = minutes.match(/\D+|\d+/g);
  let str = word.replace(/[^a-zA-Z ]/g).slice(0, 3) + "s";
  let min = digits + " " + str;
  return min;
}

function minutesToNumber(minutes) {
  const digits = minutes.match(/\D+|\d+/g);
  return digits[1];
}

const preloadLeftImages = (key) => {
  var img = new Image();
  img.src = `/fingers/left-on-${key}.png`;
  img.className = `left-${key}`;
  return img;
};

const preloadRightImages = (key) => {
  var img = new Image();
  img.src = `/fingers/right-on-${key}.png`;
  img.className = `right-${key}`;
  return img;
};

export function deleteCookie(name) {
  setCookie(name, "", -1);
}

function deleteAllCookie() {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  deleteCookie("auth_token");
  deleteCookie("tapCookie");
}

const tapSignInCookie = () => {
  let date = new Date();
  date.setTime(date.getTime() + 15 * 60 * 1000);
  document.cookie =
    "tapCookie" + "=" + true + ";path=/;expires=" + date.toGMTString();
};

export function isAuthenticated() {
  return !!getCookie("auth_token");
}

export async function kickUser() {
  await deleteAllCookie();
  tapSignInCookie();
  await window.localStorage.clear();
  await window.location.reload();
}

const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const randomColor = (colorList) => {
  return Math.floor(Math.random() * colorList.length);
};

const setUserInfo = (data) => {
  setCookie("user_id", data.id);
  setCookie("auth_token", data.auth_token);
  setCookie("profile_color", colorList[randomColor(colorList)]);
  setCookie("greet_user", greetList[randomColor(colorList)]);
  setCookie("first_name", data.first_name);
  setCookie("email", data.email);
  setCookie("phone", data.phone);
  setCookie("gender", data.gender);
  setCookie("country", data.country);
  setCookie("school", data.school_name);
  setCookie("flag", data.flag);
  setCookie("profile_image", data.image_url);
};

const popCookie = (name) => {
  let value = getCookie(name);
  deleteCookie(name);
  return value;
};

const ValidateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  } else {
    return false;
  }
};

const allowedKeys = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  " ",
  ";",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  ".",
  ",",
  "-",
  "'",
  '"',
  "?",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "{",
  "}",
  "|",
  ":",
  ";",
  "<",
  ">",
  "/",
  "?",
  "\\",
  "[",
  "]",
  "_",
  "+",
  "=",
  "Backspace"
];

function LessonBackground() {
  return [
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_1.png",
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_2.png",
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_3.png",
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_4.png",
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_5.png",
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_6.png",
    "https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_7.png"
  ]
}

function RandomImage() {
  const background_image = LessonBackground()
  return background_image[Math.floor(Math.random() * background_image.length)]
}

function LeftSpecialKey(key) {
  return {
    "!": "exclamation",
    "@": "at",
    "#": "hash",
    "$": "dollar",
    "%": "percentage"
  }[key]
}

function RightSpecialKey(key) {
  return {
    ";": "semicolon",
    " ": "space",
    "^": "caret",
    "&": "ampersand",
    "*": "asterisk",
    "(": "open",
    ")": "close",
  }[key]
}

const LeftArrayList = () => [
  "q",
  "w",
  "e",
  "r",
  "t",
  "a",
  "s",
  "d",
  "f",
  "g",
  "z",
  "x",
  "c",
  "v",
  "b",
  "1",
  "2",
  "3",
  "4",
  "5",
  "Q",
  "W",
  "E",
  "R",
  "T",
  "A",
  "S",
  "D",
  "F",
  "G",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "{lock}",
  "{tab}",
  "exclamation",
  "at",
  "hash",
  "dollar",
  "percentage",
  "rest",
]

const RightArrayList = () => [
  "h",
  "y",
  "u",
  "i",
  "o",
  "p",
  "j",
  "k",
  "l",
  "semicolon",
  "n",
  "m",
  "comma",
  "dot",
  "slash",
  "{space}",
  "6",
  "7",
  "8",
  "9",
  "0",
  "caret",
  "ampersand",
  "asterisk",
  "open",
  "close",
  "rest",
  "space",
  "H",
  "J",
  "K",
  "L",
  "N",
  "M",
  "Y",
  "U",
  "I",
  "O",
  "P",
]

export {
  headers,
  getCookie,
  setCookie,
  dateAndTimeFormat,
  minutesFormat,
  titleize,
  preloadLeftImages,
  preloadRightImages,
  deleteAllCookie,
  generateUUID,
  setUserInfo,
  popCookie,
  ValidateEmail,
  allowedKeys,
  tapSignInCookie,
  LessonBackground,
  RandomImage,
  LeftSpecialKey,
  RightSpecialKey,
  RightArrayList,
  LeftArrayList,
  dateFormat,
  minutesToNumber
};
