import { getCookie } from "helpers";
import makeRequest from "./";

export function getOverviewDetails() {
  return makeRequest({
    uri: `/v1/levels/overview`,
  });
}

export function getUserReviewDetails() {
  return makeRequest({
    uri: `/v1/users/review?user_id=${getCookie("user_id")}`,
  });
}

export function getLessonData(chapter_alias_name, alias_name) {
  return makeRequest({
    uri: `/v1/chapters/${chapter_alias_name}/lessons/${alias_name}`,
  });
}

export function getStarsCount() {
  return makeRequest({
    uri: `/v1/lessons/stars`,
  });
}
