import React from "react";

export const LetterContent = ({ contentStartIndex, content, coloring }) => {
  let first = true;
  return (
    <>
      {content
        .split("")
        .splice(contentStartIndex, 12)
        .map((character, i) => {
          const char = coloring.charAt(contentStartIndex + i);
          let color = "blue";
          if (char === "") {
            color = first ? "blue" : "black";
            first = false;
          } else {
            color = char === "0" ? `red` : `green`;
          }

          return (
            <p
              className={`character character-${color}`}
              style={{
                color: color,
              }}
              key={i}
            >
              {character === " " ? "⎵" : character}
            </p>
          );
        })}
    </>
  );
};

export const WordContent = ({
  arrayStartIndex,
  content,
  coloring,
  hideline,
}) => {
  let first = true;
  let colorCount = arrayStartIndex;
  return (
    <p className="word-font" style={{ marginTop: hideline <= 1 ? 0 : -((hideline - 1) * 45) }}>
      {content.map((line, i) => {
        return (
          <p key={i}>
            {line
              .join(" ")
              .split("")
              .map((character, j) => {
                if (j === 0 && i !== 0) {
                  colorCount += 1;
                }
                const char = coloring.charAt(colorCount);

                colorCount += 1;
                let color = "blue";
                if (char === "") {
                  color = first ? "blue" : "black";
                  first = false;
                } else {
                  color = char === "0" ? `red` : `green`;
                }
                return (
                  <span
                    key={i}
                    className={`word word-${color}`}
                    style={{
                      color: color,
                    }}
                  >
                    {character}
                  </span>
                );
              })}
            <span> </span>
          </p>
        );
      })}
    </p>
  );
};
