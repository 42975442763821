import SubmitButton from "components/SubmitButton";
import { Header } from "components/Header";
import React, { Component } from "react";
import "assets/css/Profile.css";
import { getCookie, setCookie } from "helpers";
import { updateProfile, fetchProfile } from "api/authentication";

const styles = {
  error: {
    position: "absolute",
    top: "70px",
    color: "red",
    fontSize: "18px",
    fontFamily: "Nunito",
  },
};
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.user_id = getCookie("user_id");
    this.auth_token = getCookie("auth_token");
    this.state = {
      name: "",
      email: "",
      phone: "",
      gender: "",
      country: "",
      school_name: "",
      noNameError: false,
      successMessage: "",
    };
  }

  componentDidMount() {
    if (this.auth_token && this.user_id) {
      fetchProfile(this.user_id, this.auth_token)
        .then(
          ({
            data: { email, phone, school_name, gender, country, first_name },
          }) => {
            this.setState({
              email,
              phone,
              school_name,
              gender,
              country,
              name: first_name,
            });
          }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      noNameError: false,
      successMessage: "",
    });
  };

  handleSubmit = () => {
    const { name, email, phone, gender, country, school_name } = this.state;
    let user_id = getCookie("user_id");
    let auth_token = getCookie("auth_token");

    if (!name) {
      this.setState({ noNameError: true });
      return;
    }

    let requestBody = {
      first_name: name,
      email,
      phone,
      gender,
      country,
      school_name,
    };
    updateProfile(requestBody, user_id, auth_token)
      .then(
        ({
          data: { first_name, email, phone, gender, country, school_name },
          message,
        }) => {
          this.setState({
            name: first_name,
            email,
            phone,
            gender,
            country,
            school_name,
            successMessage: message,
          });
          setCookie("first_name", first_name);
          setCookie("email", email);
          setCookie("phone", phone);
          setCookie("gender", gender);
          setCookie("country", country);
          setCookie("school_name", school_name);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      name,
      email,
      phone,
      gender,
      country,
      school_name,
      successMessage,
      noNameError,
    } = this.state;
    return (
      <div className="profile-background-image">
        <div id="container">
          <Header logoRedirect="/" />
          <div className="user-profile-container">
            <div className="profile-title-block">
              <p className="title-font">My Profile</p>
              <p className="title-description">
                {`Hi ${getCookie(
                  "first_name"
                )}, Here you can edit your profile`}
              </p>
            </div>
            <div className="profile-form-block">
              <div className="profile-form-container">
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">NAME</p>
                    <input
                      name="name"
                      value={name !== "null" ? name : ""}
                      className="profile-input"
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </div>
                  {noNameError && !name && (
                    <p style={styles.error}>Please enter a valid name</p>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">EMAIL</p>
                    <input
                      name="email"
                      value={email}
                      className="profile-input"
                      readOnly
                    />
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">PHONE NUMBER</p>
                    <input
                      name="phone"
                      value={phone !== "null" ? phone : ""}
                      className="profile-input"
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">GENDER</p>
                    <select
                      name="gender"
                      className="profile-input"
                      value={gender}
                      onChange={this.handleChange}
                    >
                      <option value="">Choose</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">COUNTRY</p>
                    <input
                      name="country"
                      className="profile-input"
                      value={country !== "null" ? country : ""}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">SCHOOL NAME</p>
                    <input
                      name="school_name"
                      className="profile-input"
                      value={school_name !== "null" ? school_name : ""}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              {successMessage && (
                <p
                  style={{
                    color: "green",
                    fontSize: "18px",
                    fontFamily: "Nunito",
                  }}
                >
                  {successMessage}
                </p>
              )}
            </div>
            <div>
              <SubmitButton
                style={{ maxWidth: 300 }}
                buttonText="Update Profile"
                buttonClick={this.handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
