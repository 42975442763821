import React, { Component } from "react";
import { getCookie, setCookie } from "helpers";
import { fetchAchievementsData, achievementOverview } from "api/achievements";
import { Header } from "components/Header";
import "assets/css/lesson/Achievements.css";
import Characters from "./Characters";
import Stars from "./Stars";
import Time from "./Time";

const styles = {
  activeBorder: {
    borderRadius: "12px",
    cursor: "pointer",
    border: "#FF0000",
    height: "6px",
    margin: "0",
    padding: "2px 65px 0px 60px",
    width: "14rem",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "60px 12px 0px",
    fontFamily: "Nunito",
    fontSize: "22px",
  },
  disableCard: {
    border: "1px solid #eeeeee",
    width: "185px",
    height: "185px",
    backgroundColor: "#eeeeee",
    borderRadius: "15px 0px 15px 15px",
  },
  enableCard: {
    border: "1px solid #ffffff",
    width: "185px",
    height: "185px",
    backgroundColor: "#ffffff",
    borderRadius: "15px 0px 15px 15px",
  },
  insideCard: {
    textAlign: "center",
    padding: "20px 12px 0px",
  },
  cardImg: {
    paddingTop: "2px",
    paddingBottom: "14px",
    width: "100px",
  },
  progressbar: {
    backgroundColor: "#CFCFCF",
    borderRadius: "3px",
    color: "#ffffff",
  },
};

const Heading = ({ headingFont, earned, maximum }) => {
  return (
    <div style={styles.heading}>
      <h3>{headingFont}</h3>
      <h3>
        <span style={{ color: "red" }}>{earned}</span>/ {maximum} Earned
      </h3>
    </div>
  );
};

const TabName = ({ isTabActive, handleChange, tabClicked, tabName }) => {
  return (
    <div className="TabSize"
      onClick={(e) => handleChange(e, tabClicked)}
      style={{
        color: isTabActive && "#FF0000",
        fontWeight: isTabActive && "700",
        padding: "0px 20px",
        width: "15vw",
      }}
    >
      <p>{tabName}</p>
      <input className="TabActiveColor"
        disabled
        style={{
          backgroundColor: isTabActive && "#FF0000",
          ...styles.activeBorder,
        }}
      />
    </div>
  );
};

class Achievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      characters: true,
      accuracy: false,
      timeSpend: false,
      actual_api_data: {},
      userdata: {},
      data: {
        stars: {},
        characters: {},
        time: {},
      },
      user_data: {},
    };
  }
  componentDidMount() {
    this.fetchOverview();
  }

  fetchOverview = async () => {
    setCookie("pervious_url", window.location.pathname);
    await achievementOverview()
      .then(({ data }) => {
        this.setState({ data }, () => {
          this.fetchUserData();
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  fetchUserData = async () => {
    const user_id = getCookie("user_id");
    let { data } = this.state;
    await fetchAchievementsData(user_id)
      .then((val) => {
        this.setState({
          actual_api_data: val.data,
        });
        let response = val.data;
        if (response) {
          data["users_speed"] = response.speed;
          data["users_stars"] = response.stars;
          data["users_time"] = response.time;
          data["users_characters"] = response.characters;
          if (response.ach_categories) {
            Object.keys(response.ach_categories).forEach((category) => {
              if (response.ach_categories[category]) {
                Object.keys(response.ach_categories[category]).map((ach_id) => {
                  if (data[category][ach_id]) {
                    let obj = {
                      user_reach_count:
                        response.ach_categories[category][ach_id][
                        "reach_count"
                        ],
                      complete: true,
                    };
                    data[category][ach_id] = {
                      ...data[category][ach_id],
                      ...obj,
                    };
                  }
                });
              }
            });
          }
        }
        this.setState({ data });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // progressCalculation = (user_points, max_points) => {
  //   return (user_points / max_points) * 100;
  // };

  handleChange = (e, tabName) => {
    if (tabName === "stars") {
      this.setState({ characters: false, accuracy: true, timeSpend: false });
    } else if (tabName === "speed") {
      this.setState({ characters: true, accuracy: false, timeSpend: false });
    } else if (tabName === "typing") {
      this.setState({ characters: false, accuracy: false, timeSpend: true });
    }
  };

  render() {
    const {
      characters,
      accuracy,
      timeSpend,
      data,
      actual_api_data,
    } = this.state;
    let characters_typed = `${(actual_api_data?.["ach_categories"]?.[
      "characters"
    ] &&
      Object.keys(actual_api_data?.["ach_categories"]?.["characters"])
        .length) ||
      0} `;
    let stars_earned = `${(actual_api_data?.["ach_categories"]?.["stars"] &&
      Object.keys(actual_api_data?.["ach_categories"]?.["stars"]).length) ||
      0} `;
    let time_spent = `${(actual_api_data?.["ach_categories"]?.["time"] &&
      Object.keys(actual_api_data?.["ach_categories"]?.["time"]).length) ||
      0} `;

    return (
      <div className="main">
        <div className="backimage">
          <div
            style={{
              width: "100%",
              maxWidth: "1440px",
              margin: "auto",
            }}
          >
            <div style={{ padding: "0 0 20px 0" }}>
              <Header logoRedirect={"/"} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "12px",
                }}
              >
                <div className="group-rectangle">
                  <h1
                    style={{
                      textAlign: "center",
                      fontFamily: "Nunito",
                      fontSize: "28px",
                      paddingBottom: "18px",
                    }}
                  >
                    Your Achievements
                  </h1>
                  <div style={{ padding: "28px 28px 5px" }}>
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        fontFamily: "Source Sans Pro",
                        textAlign: "center",
                        fontSize: "22px",
                        alignItems: "flex-end",
                      }}
                    >
                      <TabName
                        isTabActive={characters}
                        handleChange={this.handleChange}
                        tabName="Characters Typed"
                        tabClicked="speed"
                      />
                      <TabName
                        isTabActive={accuracy}
                        handleChange={this.handleChange}
                        tabName="Accuracy Stars"
                        tabClicked="stars"
                      />
                      <TabName
                        isTabActive={timeSpend}
                        handleChange={this.handleChange}
                        tabName="Time spent"
                        tabClicked="typing"
                      />
                    </div>
                    <hr
                      style={{
                        margin: "-12px",
                        color: "#EEEEEE",
                        height: "4px",
                        backgroundColor: "#EEEEEE",
                      }}
                    />
                  </div>
                  <div>
                    {characters ? (
                      <Heading
                        headingFont="Characters Typed"
                        earned={characters_typed}
                        maximum={Object.keys(data.characters).length}
                      />
                    ) : accuracy ? (
                      <Heading
                        headingFont="Accuracy Stars"
                        earned={stars_earned}
                        maximum={Object.keys(data.stars).length}
                      />
                    ) : (
                      <Heading
                        headingFont="Time Spent"
                        earned={time_spent}
                        maximum={Object.keys(data.time).length}
                      />
                    )}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="AchievementData"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "35px",
                          paddingTop: "80px",
                          paddingLeft: "20px",
                        }}
                      >
                        {characters && (
                          <Characters
                            data={data}
                            styles={styles}
                          />
                        )}
                        {accuracy && (
                          <Stars
                            data={data}
                            styles={styles}
                          />
                        )}
                        {timeSpend && (
                          <Time
                            data={data}
                            styles={styles}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Achievements;
