import React, { Component } from "react";
import "assets/css/Login.css";
import { Link } from "react-router-dom";
import emailIcon from "assets/images/svg/email-icon.svg";
import passwordIcon from "assets/images/svg/password-icon.svg";
import nameIcon from "assets/images/svg/name-icon.svg";
import phoneNumberIcon from "assets/images/svg/phone-number-icon.svg";
import { signUp, verifyOTP } from "api/authentication";
import { getCookie, setUserInfo, ValidateEmail } from "helpers";
import SubmitButton from "components/SubmitButton";
import CustomInputField from "components/CustomInputField";
import LoginDialog from "./LoginDialog";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      first_name: "",
      username: "",
      password: "",
      confirm_password: "",
      phone: "",
      otp: "",
      errorMessage: "",
      noEmailError: false,
      noPasswordError: false,
      noConfirmPasswordError: false,
      noOtpError: false,
      noNameError: false,
      profileImage: "",
      signupClicked: false,
      signupVerify: false,
    };
  }

  componentDidMount() {
    this.listener = document.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        this.state.signupClicked ? this.handleOTPSubmit() : this.handleSignup();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.listener);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: e && "",
      noNameError: false,
      noEmailError: false,
      noPasswordError: false,
      noConfirmPasswordError: false,
      noOtpError: false,
    });
  };

  handleOTPSubmit = () => {
    const { username, signupClicked, otp, base_token } = this.state;

    if (!signupClicked) {
      return;
    }

    if (!otp) {
      this.setState({
        noOtpError: true,
      });
      return;
    }

    verifyOTP({ username: username, otp_token: otp }, base_token)
      .then(({ data }) => {
        setUserInfo(data);
        setTimeout(() => window.location.pathname = getCookie("pervious_url") ? getCookie("pervious_url") : "/", 1000);
      })
      .catch((err) => {
        this.setState({
          errorMessage: err[1] ? err[1].message : err.message,
        });
      });
  };

  handleSignup = () => {
    const {
      first_name,
      username,
      password,
      confirm_password,
      signupClicked,
    } = this.state;

    if (signupClicked) {
      return;
    }

    if (!first_name || !username || !password) {
      this.setState({
        noPasswordError: !password,
        noNameError: !first_name,
        noEmailError: true,
      });
      return;
    }

    let email_validation = ValidateEmail(username);

    if (!email_validation) {
      this.setState({ noEmailError: true });
      return;
    }

    if (password !== confirm_password) {
      this.setState({
        noConfirmPasswordError: true,
      });
      return;
    }

    let requestBody = {
      first_name,
      email: username,
      password,
      user_id: getCookie("user_id"),
    };

    signUp(requestBody)
      .then((res) => {
        this.setState({
          base_token: res.data,
          signupClicked: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errorMessage: err[1] ? err[1].data && err[1].data[0] : err.message,
        });
      });
  };

  render() {
    const { handleChange, handleSignup, handleOTPSubmit } = this;
    const {
      first_name,
      username,
      phone,
      password,
      confirm_password,
      otp,
      errorMessage,
      noEmailError,
      noPasswordError,
      noConfirmPasswordError,
      noNameError,
      signupClicked,
      noOtpError,
    } = this.state;

    return (
      <div
        className="login-form-container"
        style={{
          boxShadow: "2px 2px 8px 0px grey",
        }}
      >
        <p className="login-form-title">Create your account</p>
        <p className="login-helper-font">
          {!signupClicked
            ? "Complete the form below to signup"
            : "Enter the OTP sent to your email"}
        </p>
        <div className="inputs-container">
          {!signupClicked && (
            <>
              <CustomInputField
                inputName="first_name"
                inputType="text"
                inputValue={first_name}
                inputPlaceHolder="Name"
                handleChange={handleChange}
                icon={nameIcon}
                error={noNameError}
                errorMessage="Please enter a valid name"
              />
              <CustomInputField
                inputName="username"
                inputType="email"
                inputValue={username}
                inputPlaceHolder="Email ID"
                handleChange={handleChange}
                icon={emailIcon}
                error={noEmailError}
                errorMessage="Please enter a valid email"
              />
              <CustomInputField
                inputName="phone"
                inputType="text"
                inputValue={phone}
                inputPlaceHolder="Phone Number"
                handleChange={handleChange}
                icon={phoneNumberIcon}
              />
              <CustomInputField
                inputName="password"
                inputType="password"
                inputValue={password}
                inputPlaceHolder="Password"
                handleChange={handleChange}
                icon={passwordIcon}
                error={noPasswordError}
                errorMessage="Please enter a valid password"
              />
              <CustomInputField
                inputName="confirm_password"
                inputType="password"
                inputValue={confirm_password}
                inputPlaceHolder="Confirm Password"
                handleChange={handleChange}
                icon={passwordIcon}
                error={noConfirmPasswordError}
              />
            </>
          )}
          {signupClicked && (
            <CustomInputField
              inputName="otp"
              inputType="text"
              inputValue={otp}
              inputPlaceHolder="Enter your OTP"
              handleChange={handleChange}
              icon={passwordIcon}
              error={noOtpError}
              errorMessage="Please enter a otp"
            />
          )}
          {noConfirmPasswordError && (
            <div style={{ textAlign: "left", marginBottom: "25px" }}>
              <p
                style={{
                  color: "red",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                }}
              >
                Passwords does not match!!
              </p>
            </div>
          )}
          {errorMessage && (
            <div style={{ textAlign: "left", marginBottom: "25px" }}>
              <p
                style={{
                  color: "red",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                }}
              >
                {errorMessage}
              </p>
            </div>
          )}
          {signupClicked ? (
            <SubmitButton buttonText="Signup" buttonClick={handleOTPSubmit} />
          ) : (
            <SubmitButton
              buttonText="Create Account"
              buttonClick={handleSignup}
            />
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <p className="login-bottom-font">
            <span>Already have an account?</span>
            <Link
              style={{
                color: "red",
                textDecoration: "none",
                marginLeft: "10px",
              }}
              to="/login"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default LoginDialog()(Signup);
