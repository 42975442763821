import makeRequest from "./";
export function fetchAchievementsData(user_id) {
  return makeRequest({
    uri: `/v1/users/achievements?user_id=${user_id}`,
  });
}

export function achievementOverview() {
  return makeRequest({
    uri: "/v1/achievements/overview",
  });
}
