import React from "react";

const Stars = ({ data, styles }) => {
  return (
    <>
      {Object.keys(data.stars).map((ite, i) => {
        let noUserReachCount = !data.stars[ite]?.["user_reach_count"];
        let userReachCount = data.stars[ite]?.["user_reach_count"];
        let actualReachCount = data.stars[ite]["reach_count"];
        let widthCalculation = userReachCount ? "100%" : "0%"
        return (
          <div
            key={i}
            style={
              noUserReachCount && actualReachCount
                ? styles.disableCard
                : styles.enableCard
            }
          >
            <div style={styles.insideCard}>
              <img
                src={
                  noUserReachCount && actualReachCount
                    ? data.stars[ite]["disable_image"]
                    : data.stars[ite]["enable_image"]
                }
                style={styles.cardImg}
                alt="icon"
              />
              <div
                style={{
                  backgroundColor: "#cfcfcf",
                  borderRadius: "3px",
                }}
              >
                <div>
                  <div className="progress">
                    <div
                      className="progress-inloader"
                      style={{
                        width: widthCalculation,
                        height: "1.5rem",
                        paddingTop: "4px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "10rem",
                          gap: 5,
                        }}
                      >
                        <span>
                          {data["users_stars"] >= actualReachCount
                            ? actualReachCount
                            : data["users_stars"] || 0}
                        </span>
                        <span>/</span>
                        <span>{actualReachCount}</span>
                        <span>Stars Earned</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Stars;
