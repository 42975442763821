import React from "react";

const SubmitButton = (props) => {
  return (
    <button
      style={{
        background: "rgb(255, 0, 0)",
        borderRadius: "10px",
        color: "white",
        border: "none",
        width: "100%",
        padding: "15px",
        fontSize: "26px",
        margin: "30px 0",
        cursor: "pointer",
        ...props.style,
      }}
      onClick={props.buttonClick}
    >
      <span>
        {props.icon} {props.buttonText}
      </span>
    </button>
  );
};

export default SubmitButton;
