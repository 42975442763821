import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { publicRoutes, privateRoutes } from "routes";
import { isAuthenticated } from "helpers";

export default class CustomRoutes extends Component {
  render() {
    return (
      <Switch>
        {privateRoutes.map((route, i) => (
          <PrivateRoute
            exact
            key={i}
            path={route.path}
            component={route.component}
          />
        ))}
        {publicRoutes.map((route, i) => (
          <PublicRoute
            exact
            key={i}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    );
  }
}

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
