import React from "react";

const CustomButton = (props) => {
  return (
    <button
      className={props.className}
      onClick={props.buttonClick}
      style={props.style}
      disabled={props.buttonDisable}
    >
      <span>
        {props.icon} {props.buttonText}
      </span>
    </button>
  );
};

export default CustomButton;
