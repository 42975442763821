import { apiHost } from "config";
import { headers } from "helpers";

export default async function({ uri, method = "GET", body, authorization }) {
  return fetch(apiHost + uri, {
    method,
    body,
    headers: authorization
      ? Object.assign(headers, {
          Authorization: authorization,
          "Content-Type": "application/json",
        })
      : headers(),
  }).then(async (response) => {
    if (response.ok) {
      try {
        return response.json();
      } catch (err) {
        return true;
      }
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}
