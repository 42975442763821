import { Header } from "components/Header";
import React, { Component } from "react";
import "assets/css/test/Test.css";
import emptyLeader from "assets/images/svg/leaderboard.svg";
import { getTestDetails, getTestLeaderboard } from "api/testing";
import typingDetails from "assets/images/svg/typing_details.svg";
import { dateFormat, titleize } from "helpers";

class TestLeaderBoardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_leaderboard_data: [],
      copied: false,
      test_detail: {},
      empty: true
    };
  }

  componentDidMount() {
    this.fetchTestLeaderboard();
    this.fetchTestDetails();
  }

  groupBy = (arrayValue, key) => {
    return arrayValue.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  fetchTestLeaderboard = async () => {
    console.log(this.props.match.params.id);
    let test_id = this.props.match.params.id;
    await getTestLeaderboard(test_id).then(({ data }) => {
      let groupData = {}
      groupData = this.groupBy(data, 'user_id')
      let final_array = Object.keys(groupData).map((key) => {
        // let val = Math.max.apply(Math, groupData[key].map(function (o) { return o.score; }))
        return groupData[key] && groupData[key][0]
      })
      this.setState({
        test_leaderboard_data: final_array,
      })
    }

    );
  };

  fetchTestDetails = async () => {
    let test_id = this.props.match.params.id;
    await getTestDetails(test_id)
    .then(({ data }) => {
      this.setState({
        test_detail : data,
        empty: false
      })
    })
  }

  copyClickHandler = () => {
    this.setState({ copied: true });
  };

  render() {
    const { test_leaderboard_data, test_detail, empty } = this.state;
    console.log(test_detail, "details");
    return (
      <div className="background-image">
        <div
          style={{
            width: "100%",
            maxWidth: "1440px",
            margin: "auto",
          }}
        >
          <Header logoRedirect={"/competitions"} />
          <div className="test-success-center-card"
            style={{
              width: "80%",
              margin: "0 auto",
              display: "flex",
              gap: "25px",
            }}
          >
            <div style={{ flex: "0.8" }}>
              <div className="test-success-left-top-card">
                <div style={{ position: "relative" }}>
                  <span className="success-left-top-title">
                    {titleize(test_detail.name)}
                  </span>
                </div>
                <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                  <div style={{textAlign: "center"}}>
                    <img
                      src={typingDetails}
                      style={{ width: "260px", height: "366px" }}
                      alt="girl"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      rowGap: "10px",
                    }}
                  >
                    <div style={{flex: "0 0 50%"}}>
                      <p><span className="test-leader-title">Created By</span></p>
                      <p><span className="test-leader-value">{test_detail.created_by_name ? test_detail.created_by_name : "-"}</span></p>
                    </div>
                    <div style={{flex: "0 0 50%"}}>
                      <p>
                        <span className="test-leader-title">
                          No Of Attempts
                        </span>
                      </p>
                      <p>
                        <span className="test-leader-value">
                          {test_detail.no_of_attempts ? test_detail.no_of_attempts : "-"}
                        </span>
                      </p>
                    </div>
                    <div style={{flex: "0 0 50%"}}>
                      <p>
                        <span className="test-leader-title">
                          Created On
                        </span>
                      </p>
                      <p>
                        <span className="test-leader-value">
                          {test_detail.created_on ? dateFormat(test_detail?.created_on) : "-" }
                        </span>
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="test-success-right-card" style={{ flex: "1.2" }}>
                <div>
                  <p className="test-success-right-title">Leaderboard</p>
                </div>
              {!empty && test_leaderboard_data.length !== 0 ?
                <table
                  style={{
                    borderSpacing: "10px 20px",
                    width: "100%",
                  }}
                >
                  <tr className="test-success-column-name">
                    <th>Name</th>
                    <th>Speed</th>
                    <th>Accuracy</th>
                    <th>Points</th>
                  </tr>
                  {test_leaderboard_data?.map((record, i) => {
                    if (i <= 20) {
                      return (
                        <tr key={i} className="test-success-column-value">
                          <td>{record.name > 19 ? `${record?.name.slice(0, 19)}..` : record?.name}<br /><span style={{fontSize: "16px", color: "#b39bfa"}}>{record?.school_name.length > 35 ? `${record?.school_name.slice(0, 35)}...` : record?.school_name}</span></td>
                          <td>{`${record.speed} WPM`}</td>
                          <td>{`${record.accuracy} %`}</td>
                          <td>{`${record.score} pts.`}</td>
                        </tr>
                      );
                    }
                  })}
                </table>
                : empty ? <></> : <div style={{height: "100%",display: "flex",alignItems: "center",justifyContent: "center"}}>
                    <img style={{width: "80%"}} src={emptyLeader} alt="leader" />
                </div>
                }
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestLeaderBoardPage;
