import React, { Component } from "react";
import "assets/css/Login.css";
import { Link } from "react-router-dom";
import emailIcon from "assets/images/svg/email-icon.svg";
import passwordIcon from "assets/images/svg/password-icon.svg";
import { forgotPassword, otpVerify, setPassword } from "api/authentication";
import { setUserInfo } from "helpers";
import SubmitButton from "components/SubmitButton";
import CustomInputField from "components/CustomInputField";
import LoginDialog from "./LoginDialog";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      username: "",
      password: "",
      confirm_password: "",
      otp: "",
      errorMessage: "",
      noEmailError: false,
      noPasswordError: false,
      noConfirmPasswordError: false,
      getClicked: false,
      loginVerify: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: e && "",
      noEmailError: false,
      noPasswordError: false,
      noConfirmPasswordError: false,
      noOtpError: false,
    });
  };

  componentDidMount() {
    const { getClicked, loginVerify } = this.state;
    this.listener = document.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        !getClicked
          ? this.handleSendOTP()
          : !loginVerify
            ? this.handleVerifyOTP()
            : this.handleSetPassword();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.listener);
  }

  handleSendOTP = () => {
    const { username, getClicked, loginVerify } = this.state;

    if (getClicked || loginVerify) {
      return;
    }

    if (!username) {
      this.setState({ noEmailError: true });
      return;
    }

    forgotPassword({ username: username })
      .then((res) => {
        this.setState({
          base_token: res.data,
          getClicked: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errorMessage: err[1] ? err[1].message : err.message,
        });
      });
  };

  handleVerifyOTP = () => {
    const { username, otp, base_token, getClicked, loginVerify } = this.state;

    if (!getClicked || loginVerify) {
      return;
    }

    if (!otp) {
      this.setState({ errorMessage: "Please enter a valid otp" });
      return;
    }

    otpVerify({ username: username, otp_token: otp }, base_token)
      .then((res) => {
        this.setState({
          loginVerify: true,
          base_token: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errorMessage: err[1] ? err[1].message : err.message,
        });
      });
  };

  handleSetPassword = () => {
    const {
      password,
      confirm_password,
      base_token,
      getClicked,
      loginVerify,
    } = this.state;

    if (!getClicked || !loginVerify) {
      return;
    }

    if (!password) {
      this.setState({ noPasswordError: true });
      return;
    }

    if (password !== confirm_password) {
      this.setState({ noConfirmPasswordError: true });
      return;
    }

    setPassword({ password: password }, base_token)
      .then(({ data }) => {
        setUserInfo(data);
        setTimeout(() => this.props.history.push("/"), 1000);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errorMessage: err[1] ? err[1].message : err.message,
        });
      });
  };

  render() {
    const {
      handleChange,
      handleSendOTP,
      handleVerifyOTP,
      handleSetPassword,
    } = this;

    const {
      username,
      password,
      confirm_password,
      otp,
      errorMessage,
      noEmailError,
      noPasswordError,
      noConfirmPasswordError,
      getClicked,
      noOtpError,
      loginVerify,
    } = this.state;

    return (
      <div
        className="login-form-container"
        style={{
          boxShadow: "2px 2px 8px 0px grey",
        }}
      >
        <p className="login-form-title">Forgot Password</p>
        <p className="login-helper-font">
          {!getClicked
            ? "Enter your mail to get OTP"
            : !loginVerify
              ? "Enter the OTP sent to your email"
              : "Set a new password"}
        </p>
        <div className="inputs-container">
          {!getClicked && (
            <CustomInputField
              inputName="username"
              inputType="email"
              inputValue={username}
              inputPlaceHolder="Email ID"
              handleChange={handleChange}
              icon={emailIcon}
              error={noEmailError}
              errorMessage="Please enter a valid email"
            />
          )}
          {getClicked && !loginVerify && (
            <CustomInputField
              inputName="otp"
              inputType="text"
              inputValue={otp}
              inputPlaceHolder="Enter OTP"
              handleChange={handleChange}
              icon={passwordIcon}
              error={noOtpError}
              errorMessage="Please enter a valid otp"
            />
          )}
          {loginVerify && (
            <CustomInputField
              inputName="password"
              inputType="password"
              inputValue={password}
              inputPlaceHolder="Enter Password"
              handleChange={handleChange}
              icon={passwordIcon}
              error={noPasswordError}
              errorMessage="Please enter a valid password"
            />
          )}
          {loginVerify && (
            <CustomInputField
              inputName="confirm_password"
              inputType="password"
              inputValue={confirm_password}
              inputPlaceHolder="Confirm Password"
              handleChange={handleChange}
              icon={passwordIcon}
              error={noConfirmPasswordError}
            />
          )}

          {noConfirmPasswordError && (
            <div style={{ textAlign: "left", marginBottom: "25px" }}>
              <p
                style={{
                  color: "red",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                }}
              >
                Passwords does not match!!
              </p>
            </div>
          )}
          {errorMessage && (
            <div style={{ textAlign: "left", marginBottom: "25px" }}>
              <p
                style={{
                  color: "red",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                }}
              >
                {errorMessage}
              </p>
            </div>
          )}
          {!getClicked ? (
            <SubmitButton
              buttonText="Request OTP"
              buttonClick={handleSendOTP}
            />
          ) : !loginVerify ? (
            <SubmitButton
              buttonText="Verify OTP"
              buttonClick={handleVerifyOTP}
            />
          ) : (
            <SubmitButton
              buttonText="Set Password"
              buttonClick={handleSetPassword}
            />
          )}
        </div>
        {!getClicked && !loginVerify && (
          <div>
            <p className="login-bottom-font">
              <span>Already have an account?</span>
              <Link
                style={{
                  color: "red",
                  marginLeft: "10px",
                  textDecoration: "none",
                }}
                to="/login"
              >
                Login
              </Link>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default LoginDialog()(ForgotPassword);
