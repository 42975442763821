import React from 'react';

export const NextIcon = () => {
    return(
        <span className="next-icon">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.5106 15.1457L6.13556 15.1457" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.6956 7.36405C17.6956 7.36405 25.5101 11.5762 25.5101 15.1438C25.5101 18.7139 17.6956 22.9273 17.6956 22.9273" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </span>
    )
}