import React, { Component } from "react";
import "assets/css/test/Test.css";
import { Header } from "components/Header";
import star from "assets/images/photos/test_star.png";
import { getTestListData, getOverallLeaderboard } from "api/testing";
import { getCookie, titleize, minutesToNumber, setCookie } from "helpers";
import OneTapLogin from "views/authentication/OneTapLogin";
import noComplain from "assets/images/svg/competition.svg";
import leaderboardIcon from "assets/images/svg/comp.svg"
import oneSec from "assets/images/svg/1.svg";
import twoSec from "assets/images/svg/2.svg";
import threeSec from "assets/images/svg/3.svg";
import fiveSec from "assets/images/svg/5.svg";
import emptyLeader from "assets/images/svg/leaderboard.svg";
import tenSec from "assets/images/svg/10.svg";

class TestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overall_tests_data: [],
      overall_leaderboard_data: [],
      colorList: [
        "#8B0000",
        "#483D8B",
        "#696969",
        "#FF69B4",
        "#191970",
        "#FF4500",
        "#800080",
        "#000000",
      ],
      empty: true,
    };
  }

  componentDidMount() {
    this.fecthTestList();
    this.fetchLeaderboard();
  }

  fecthTestList = () => {
    const auth_token = getCookie("auth_token");

    getTestListData(auth_token)
      .then(({ data }) => {
        this.setState({ overall_tests_data: data });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  fetchLeaderboard = () => {
    const auth_token = getCookie("auth_token");
    setCookie("pervious_url", window.location.pathname);
    getOverallLeaderboard(auth_token).then(({ data }) => {
      this.setState({
        overall_leaderboard_data: data,
        empty: false
      });
    });
  };

  createTestHandler = () => {
      this.props.history.push("/competitions/create");
    }

  testClickHandler = (test) => {
    this.props.history.push({
      pathname: `/competitions/${test.id}`,
      state: test,
    })
  };

  render() {
    const {
      overall_tests_data,
      overall_leaderboard_data,
      colorList,
      empty
    } = this.state;
    const { createTestHandler } = this;
    return (
      <React.Fragment>
        <div className="background-image">
          <div
            style={{
              width: "100%",
              maxWidth: "1440px",
              margin: "auto",
            }}
          >
            <Header logoRedirect={"/"} />
            <OneTapLogin />
            <div className="main-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  gap: "15px",
                }}
              >
                <div style={{ width: "90%" }}>
                  <div className="inner-card">
                    <h1>Upcoming Competition</h1>
                    <div className="upcoming-competition"
                      style={{
                        display: "flex",
                        margin: "20px",
                        flexWrap: "wrap",
                        gap: "18px",
                      }}
                    >
                      {!empty && overall_tests_data.length !== 0 ? overall_tests_data.map((test, i) => {
                        return (
                          <div style={{position: "relative"}} key={i}>
                            <div
                              key={i}
                              className="card"
                              style={{ position: "relative"}}
                            >
                              <div
                                  style={{
                                    display: "flex",
                                    fontFamily: "Source Sans Pro",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    gap: "5px",
                                    position: "relative",
                                    top: '10px',
                                    left: '10px'
                                  }}
                                >
                                  
                                  {minutesToNumber(test?.time_line) === '1' ?
                                    <p>
                                    <img src={oneSec} alt="test-clock" />
                                    </p>
                                    : minutesToNumber(test?.time_line) === '2' ?  <p>
                                    <img src={twoSec} alt="test-clock" />
                                    </p> : minutesToNumber(test?.time_line) === '3' ? <p>
                                    <img src={threeSec} alt="test-clock" />
                                    </p> : minutesToNumber(test?.time_line) === '5' ? <p>
                                    <img src={fiveSec} alt="test-clock" />
                                    </p> : minutesToNumber(test?.time_line) === '10' ? <p>
                                    <img src={tenSec} alt="test-clock" />
                                    </p> : <></>}
                                </div>
                                <div onClick={() => this.props.history.push(`/competitions/${test.id}/leaderboard`)} style={{position: "absolute",right: "0px",top: "0px",padding: " 5px 5px",cursor: "pointer",width: '55px',height: '55px',backgroundColor: 'lavender',borderBottomLeftRadius: '75%',paddingLeft: '10px'}} >
                                  <img src={leaderboardIcon}  style={{width: "30px"}}/>
                              </div>
                              
                              <img
                                src={star}
                                style={{ width: "74px" }}
                                alt="test-star"
                              />

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "10px",
                                  paddingBottom: '5px',
                                }}
                              >
                                <p className="title-para">
                                  {test.name.length > 6
                                    ? `${titleize(test.name.length > 21 ? `${test.name.slice(0, 20)}...` : test.name)}`
                                    : test.name}
                                </p>
                              </div>
                              <div onClick={() => this.testClickHandler(test)} style={{cursor: "pointer",borderTop: "1px solid rgba(255, 255, 255)",padding: "10px 0px"}}>
                                <p className="try-text">Try Now</p>
                              </div>
                            </div>
                          </div>
                        );
                      }) 
                      : empty ? <></> :
                      <>
                      <div style={{display: "flex",justifyContent: "center",alignItems: "center",width: "100%",height: "400px"}}>
                        <img src={noComplain} style={{width: "55%"}} />
                      </div>
                      </>}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="right-card" style={{ marginBottom: "16px" }}>
                    <h1 style={{ padding: "10px 0px" }}>
                      Create New Competition
                    </h1>
                    <p>
                      Create a competition and share with your friends to
                      compete against each other around the world
                    </p>
                    <button
                      className="create-btn"
                      style={{ cursor: "pointer" }}
                      onClick={createTestHandler}
                    >
                      Create
                    </button>
                  </div>
                  <div className="right-card">
                    <h1>LeaderBoard</h1>
                    {!empty && overall_leaderboard_data.length !== 0 ? <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0px",
                          flexDirection: "column",
                          gap: "20px"
                        }}
                      >
                        <div 
                          style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{flex: "1", fontSize: "22px",fontWeight: 800}}>Name</p>
                          <p style={{flex: "1", fontSize: "22px",fontWeight: 800,textAlign: "right"}}>Point</p>
                        </div>
                        {overall_leaderboard_data?.map((person, i) => {
                          return (
                            <div 
                              key={i}
                              style={{
                                display: "flex",
                                gap:"20px",
                                justifyContent: "space-between"
                              }}
                            >
                              <div style={{display: "flex", alignItems: "center",gap: "10px"}}>
                                <div 
                                  style={{
                                    background: colorList[Math.floor(Math.random() * 8)],
                                    height: "45px",
                                    width: "45px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                  }}
                                >
                                <p
                                  style={{
                                    color: "#fff",
                                    fontSize: "26px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {person?.first_name?.[0].toUpperCase() || "?"}
                                </p>
                                </div>
                                <div >
                                  <p className="leaderboard-username">
                                    {person?.first_name.length > 19 ? `${person?.first_name.slice(0, 19)}..` : person?.first_name || "Anonymous"}
                                  </p>
                                  {person?.school_name && (
                                    <p
                                      className="leaderboard-schoolname"
                                      style={{ fontSize: "16px", color: "#6e6d6d" }}
                                    >
                                      {person?.school_name.length > 37 ? `${person?.school_name.slice(0, 37)}...` : person?.school_name}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <p className="leaderboard-points">
                                  {person?.test_score.toFixed(0) || 0}
                                </p>
                              </div>
                            </div>
                          )})}
                      </div> : empty ? <></> : <div style={{height: "100%",padding: "40px 0px",display: "flex",alignItems: "center",justifyContent: "center"}}>
                        <img style={{width: "80%"}} src={emptyLeader} alt="leader" />
                      </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TestPage;
