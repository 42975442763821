import React from "react";
import CustomRoutes from "routes/CustomRoutes";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/App.css";

const App = () => {
  return (
    <BrowserRouter>
      <CustomRoutes />
    </BrowserRouter>
  );
};

export default App;
