import makeRequest from ".";

export function signUp(requestBody) {
  return makeRequest({
    uri: `/signup`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function login(requestBody) {
  return makeRequest({
    uri: `/login`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function signInWithGoogle(requestBody) {
  return makeRequest({
    uri: `/auth-request`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateProfile(requestBody, user_id, auth_token) {
  return makeRequest({
    uri: `/v1/users/${user_id}`,
    method: "PUT",
    authorization: `API_KEY ${auth_token}`,
    body: JSON.stringify(requestBody),
  });
}

export function forgotPassword(requestBody) {
  return makeRequest({
    uri: `/forgot_password`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function resetPassword(requestBody, auth_token) {
  return makeRequest({
    uri: `/reset_password`,
    method: "PUT",
    authorization: `API_KEY ${auth_token}`,
    body: JSON.stringify(requestBody),
  });
}

export function setPassword(requestBody, auth_token) {
  return makeRequest({
    uri: `/set_password`,
    method: "PUT",
    authorization: `base64 ${auth_token}`,
    body: JSON.stringify(requestBody),
  });
}

export function otpVerify(requestBody, auth_token) {
  return makeRequest({
    uri: `/otp_verify`,
    method: "PUT",
    body: JSON.stringify(requestBody),
    authorization: `base64 ${auth_token}`,
  });
}

export function verifyOTP(requestBody, auth_token) {
  return makeRequest({
    uri: `/verify_otp`,
    method: "PUT",
    body: JSON.stringify(requestBody),
    authorization: `base64 ${auth_token}`,
  });
}

export function fetchProfile(user_id, auth_token) {
  return makeRequest({
    uri: `/v1/users/${user_id}`,
    authorization: `API_KEY ${auth_token}`,
  });
}
