import React from 'react';
import Select from 'react-select';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white', minHeight: "50px", margin: "5px" }),
    option: (styles, { isDisabled }) => {
        return {
            ...styles,
            // backgroundColor: isDisabled ? 'red' : "blue",
            color: 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
};

const ReactSelect = ({ value, options, onHandleChange, placeholder, type }) => {
    return (
        <div style={{ width: '100%' }}>
            <Select
                defaultValue={value}
                onChange={(e) => onHandleChange(e, type)}
                options={options}
                placeholder={placeholder}
                styles={colourStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#9bd290',
                        primary: '#9bd290',
                    },
                })}
            />
        </div>
    );
};
export default ReactSelect;