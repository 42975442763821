import React, { Component } from "react";
import "assets/css/lesson/Home.css";
import "assets/css/App.css";
import levelOne from "assets/images/svg/level1.svg";
import levelTwo from "assets/images/svg/level2.svg";
import levelThree from "assets/images/svg/level3.svg";
import levelFour from "assets/images/svg/level4.svg";
import levelFive from "assets/images/svg/level5.svg";
import levelSix from "assets/images/svg/level6.svg";
import batchImage from "assets/images/svg/batch-icon.svg";
import overallSpeedometer from "assets/images/svg/overall-speedometer.svg";
import speedometer from "assets/images/svg/speedometer.svg";
import accuracy from "assets/images/svg/accuracy.svg";
import metaStar from "assets/images/svg/meta-star.svg";
import sandglass from "assets/images/svg/sandglass.svg";
import overallAccuracy from "assets/images/svg/overall-accuracy .svg";
import overallTime from "assets/images/svg/overall-time.svg";
import star from "assets/images/svg/star.svg";
import coins from "assets/images/svg/coins.svg";
import {
  getOverviewDetails,
  getUserReviewDetails,
  getStarsCount,
} from "api/home";
import StarGenerator from "views/lessons/home/StarGenerator";
import { Header } from "components/Header";
import { getCookie, setCookie, generateUUID, popCookie } from "helpers";
import { withRouter } from "react-router-dom";
import { IoIosArrowDropupCircle } from "react-icons/io";
import OneTapLogin from "views/authentication/OneTapLogin";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ChapterCard } from "./chapterCard";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overview_details: [],
      user_data: [],
      fisrt_card: 1,
      reach_count: 0,
      star_count: 0,
      totalStar: {},
      totalLesson: 0,
      badgeStars: 0,
      scrollTopValue: 0,
      chapterCard: false,
    };
  }

  componentDidMount() {
    this.fetchOverviewData();
    if (!getCookie("user_id")) {
      setCookie("user_id", generateUUID());
    }
    this.scrollFunction();
  }

  componentWillUnmount() {
    setCookie("scrollTopValue", window.scrollY);
  }

  scrollFunction = () => {
    let scrollY = parseInt(popCookie("scrollTopValue"));
    setTimeout(() => {
      window.scrollTo({ top: scrollY, behavior: "smooth" });
    }, 1000);
  };

  fetchOverviewData = async () => {
    setCookie("pervious_url", window.location.pathname);
    await getOverviewDetails()
      .then(({ data }) => {
        this.setState(
          {
            overview_details: data,
          },
          () => this.fetchUserReviewData()
        );
      })
      .catch((err) => console.log(err));
  };

  fetchUserReviewData = async () => {
    await getUserReviewDetails()
      .then(({ data }) => {
        const { overview_details } = this.state;
        let user_data = data;
        if (user_data) {
          this.setState(
            {
              user_data,
              totalLesson: user_data?.lessons_count,
            },
            () => this.allStarsCount()
          );
          overview_details.forEach((level) => {
            if (
              Object.prototype.hasOwnProperty.call(user_data.levels, level.id)
            ) {
              Object.keys(level.chapter_groups).forEach((group) => {
                if (
                  Object.prototype.hasOwnProperty.call(
                    user_data.levels[level.id].chapter_groups,
                    group
                  )
                ) {
                  Object.keys(level.chapter_groups[group].chapters).forEach(
                    (chapter) => {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          user_data.levels[level.id].chapter_groups[group]
                            .chapters,
                          chapter
                        )
                      ) {
                        const overview_chapter =
                          level.chapter_groups[group].chapters[chapter];
                        const user_chapter =
                          user_data.levels[level.id].chapter_groups[group]
                            .chapters[chapter];
                        overview_chapter["chapter_accuracy"] =
                          user_chapter.accuracy;
                        overview_chapter["chapter_characters"] =
                          user_chapter.characters;
                        overview_chapter["chapter_speed"] = user_chapter.speed;
                        overview_chapter["chapter_stars"] = user_chapter.stars;
                        overview_chapter["chapter_test_count"] =
                          user_chapter.test_count;
                        overview_chapter["chapter_time"] = user_chapter.time;
                        Object.keys(
                          level.chapter_groups[group].chapters[chapter].lessons
                        ).map((lesson) => {
                          if (
                            Object.prototype.hasOwnProperty.call(
                              user_data.levels[level.id].chapter_groups[group]
                                .chapters[chapter].lessons,
                              lesson
                            )
                          ) {
                            const overview_lesson =
                              level.chapter_groups[group].chapters[chapter]
                                .lessons[lesson];
                            const user_lesson =
                              user_data.levels[level.id].chapter_groups[group]
                                .chapters[chapter].lessons[lesson];
                            overview_lesson["lesson_accuracy"] =
                              user_lesson.accuracy;
                            overview_lesson["lesson_characters"] =
                              user_lesson.characters;
                            overview_lesson["lesson_speed"] = user_lesson.speed;
                            overview_lesson["lesson_stars"] = user_lesson.stars;
                            overview_lesson["lesson_test_count"] =
                              user_lesson.test_count;
                            overview_lesson["lesson_time"] = user_lesson.time;
                            this.setState({
                              overview_details,
                            });
                          }
                        });
                      }
                    }
                  );
                }
              });
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  allStarsCount = async () => {
    await getStarsCount()
      .then(({ data }) => {
        const { user_data } = this.state;
        let badgeLimit = data / 6;
        let badgeName = "iron";
        let fisrt_card = 1;
        let badgeStars = user_data?.stars;
        if (user_data?.stars >= 0 && user_data?.stars < badgeLimit) {
          badgeName = "iron";
          badgeStars = user_data?.stars;
          fisrt_card = 1;
        } else if (
          user_data?.stars > badgeLimit &&
          user_data?.stars <= badgeLimit * 2
        ) {
          badgeName = "bronze";
          badgeStars = user_data?.stars - badgeLimit;
          fisrt_card = 2;
        } else if (
          user_data?.stars > badgeLimit * 2 &&
          user_data?.stars <= badgeLimit * 3
        ) {
          badgeName = "silver";
          badgeStars = user_data?.stars - badgeLimit * 2;
          fisrt_card = 3;
        } else if (
          user_data?.stars > badgeLimit * 3 &&
          user_data?.stars <= badgeLimit * 4
        ) {
          badgeName = "gold";
          badgeStars = user_data?.stars - badgeLimit * 3;
          fisrt_card = 4;
        } else if (
          user_data?.stars > badgeLimit * 4 &&
          user_data?.stars <= badgeLimit * 5
        ) {
          badgeName = "platinum";
          badgeStars = user_data?.stars - badgeLimit * 4;
          fisrt_card = 5;
        } else if (
          user_data?.stars > badgeLimit * 5 &&
          user_data?.stars <= badgeLimit * 6
        ) {
          badgeName = "diamond";
          badgeStars = user_data?.stars - badgeLimit * 5;
          fisrt_card = 6;
        }
        this.setState(
          { lesson_stars: data, fisrt_card, badgeName, badgeStars, badgeLimit },
          () => this.progressCalculation()
        );
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  firstCard = () => {
    const { fisrt_card } = this.state;
    if (fisrt_card === 1) {
      return levelOne;
    } else if (fisrt_card === 2) {
      return levelTwo;
    } else if (fisrt_card === 3) {
      return levelThree;
    } else if (fisrt_card === 4) {
      return levelFour;
    } else if (fisrt_card === 5) {
      return levelFive;
    } else if (fisrt_card === 6) {
      return levelSix;
    }
  };

  progressCalculation = () => {
    const { badgeStars, badgeLimit } = this.state;
    const star_count = Math.floor((badgeStars / badgeLimit) * 100);
    this.setState({ star_count: star_count });
  };

  lessonClickHandler = (chapter_alias_name, lesson) => {
    this.props.history.push(
      `chapters/${chapter_alias_name}/lessons/${lesson.alias_name}`
    );
  };

  chapterCardClose = () => {
    this.setState({ chapterCard: false });
  };

  pageScroll = (chapter_name) => {
    this.setState({ chapterCard: false }, () => {
      let element = document.getElementById(chapter_name);
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  };

  render() {
    const {
      overview_details,
      user_data,
      star_count,
      fisrt_card,
      badgeName,
      chapterCard,
    } = this.state;
    const {
      lessonClickHandler,
      firstCard,
      chapterCardClose,
      pageScroll,
    } = this;
    return (
      <div className={"home-bg"}>
        <div id="container">
          <Header logoRedirect={"/"} />
          <OneTapLogin />
          {chapterCard && (
            <ChapterCard
              overview_details={overview_details}
              close={chapterCardClose}
              pageScroll={pageScroll}
            />
          )}
          <div className="main-container">
            <div className="overview-container">
              <div className="nest-card">
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img className="nest-image" src={firstCard()} alt="nest" />
                </div>
                <p className="nest-card-font">
                  {fisrt_card} / <span>6</span>
                </p>
                <p
                  style={{
                    color: "white",
                    paddingBottom: "10px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontFamily: "Nunito",
                  }}
                >
                  Levels
                </p>
              </div>
              <div className="batch-card">
                <div className="flex">
                  <img src={coins} alt="icon" />
                  <div className="next-batch-container">
                    <img
                      src={batchImage}
                      style={{ margin: "10px" }}
                      alt="icon"
                    />
                    <p>{badgeName}</p>
                  </div>
                </div>
                <div className="earned-star">
                  You have earned
                  <span className="earned-star-count">
                    {user_data?.stars ? user_data?.stars : 0}
                  </span>
                  <img src={star} alt="star" />
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-loader"
                    style={{ width: star_count + "%", color: star_count < 15 ? '#000000' : "#ffffff" }}
                  >
                    {star_count ? `${star_count}%` : `0%`}
                  </div>
                </div>
              </div>
              <div className="overall-status-card">
                <p className="overall-main-font">Overall Stats</p>
                <div className="meta-block">
                  <div className="flex">
                    <img
                      className="overall-meta-icon"
                      src={overallSpeedometer}
                      alt="icon"
                    />
                    <div className="meta-text-block">
                      <p>Avg Speed</p>
                      <p>{`${Math.floor(user_data?.speed) || 0} WPM`}</p>
                    </div>
                  </div>
                  <div className="flex">
                    <img
                      className="overall-meta-icon"
                      src={overallAccuracy}
                      alt="icon"
                    />
                    <div className="meta-text-block">
                      <p>Accuracy</p>
                      <p>{`${Math.floor(user_data?.accuracy) || 0} %`}</p>
                    </div>
                  </div>
                  <div className="flex">
                    <img
                      className="overall-meta-icon"
                      src={overallTime}
                      alt="icon"
                    />
                    <div className="meta-text-block">
                      <p>Total Time</p>
                      <p>
                        {user_data?.time < 60
                          ? `${user_data?.time} sec`
                          : user_data?.time
                            ? `${Math.floor(user_data?.time / 60)} min`
                            : "0 sec"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chapters-container">
              <div
                className="chapter-group-card"
                style={{
                  margin: 0,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <marquee
                  width="85%"
                  direction="left"
                  style={{ padding: 15, fontSize: 20 }}
                >
                  Learn typing by joining "30 day Typing Mastery Challenge"
                </marquee>
                <a className="marquee-btn"
                  href="https://wa.me/+919880921111"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    border: "1px solid red",
                    color: "#fff",
                    backgroundColor: "red",
                    padding: "8px 22px",
                    borderRadius: "5px",
                    textDecoration: "none",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Enrol for Free
                </a>
              </div>
            </div>
            <div className="chapters-container">
              {overview_details &&
                overview_details.map(
                  (level, level_index) =>
                    Object.keys(level.chapter_groups).length > 0 && (
                      <div className="chapter-group-card" key={level_index}>
                        <div className="level-tag">
                          <p>{level.name}</p>
                        </div>
                        {Object.keys(level.chapter_groups).map(
                          (group, grp_index) => {
                            return (
                              <React.Fragment key={grp_index}>
                                {Object.keys(
                                  level.chapter_groups[group].chapters
                                ).length > 0 && (
                                    <div className="chapter-group-title">
                                      <p>{level.chapter_groups[group].name}</p>
                                    </div>
                                  )}
                                {Object.keys(
                                  level.chapter_groups[group].chapters
                                ).map((chapter, i) => {
                                  return (
                                    <div
                                      className="chapter-container"
                                      key={i}
                                      id={`${level.chapter_groups[group].chapters[chapter].name}`}
                                    >
                                      <div className="chapter-title-block">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <p className="chapter-title">
                                            {
                                              level.chapter_groups[group]
                                                .chapters[chapter].name
                                            }
                                          </p>

                                          <p
                                            style={{
                                              margin: "5px 10px",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <AiOutlineInfoCircle
                                              style={{
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  chapterCard: !this.state
                                                    .chapterCard,
                                                })
                                              }
                                            />
                                          </p>
                                        </div>
                                        {level.chapter_groups[group].chapters[
                                          chapter
                                        ].chapter_stars && (
                                            <div className="chapter-meta-block">
                                              <div className="flex">
                                                <img
                                                  className="overall-meta-icon"
                                                  src={speedometer}
                                                  alt="icon"
                                                />
                                                <div className="chapter-meta-text-block">
                                                  <p>Avg Speed</p>
                                                  <p>{`${Math.floor(
                                                    level.chapter_groups[group]
                                                      .chapters[chapter]
                                                      .chapter_speed
                                                  ) || "-"} WPM`}</p>
                                                </div>
                                              </div>
                                              <div className="flex">
                                                <img
                                                  className="overall-meta-icon"
                                                  src={accuracy}
                                                  alt="icon"
                                                />
                                                <div className="chapter-meta-text-block">
                                                  <p>Accuracy</p>
                                                  <p>{`${Math.floor(
                                                    level.chapter_groups[group]
                                                      .chapters[chapter]
                                                      .chapter_accuracy
                                                  ) || "-"} %`}</p>
                                                </div>
                                              </div>
                                              <div className="flex">
                                                <img
                                                  className="overall-meta-icon"
                                                  src={sandglass}
                                                  alt="icon"
                                                />
                                                <div className="chapter-meta-text-block">
                                                  <p>Total Time</p>
                                                  <p>
                                                    {level.chapter_groups[group]
                                                      .chapters[chapter]
                                                      .chapter_time < 60
                                                      ? `${level.chapter_groups[group].chapters[chapter].chapter_time} sec`
                                                      : `${Math.floor(
                                                        level.chapter_groups[
                                                          group
                                                        ].chapters[chapter]
                                                          .chapter_time / 60
                                                      )} min`}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="flex">
                                                <img
                                                  className="overall-meta-icon"
                                                  src={metaStar}
                                                  alt="icon"
                                                />
                                                <div className="chapter-meta-text-block">
                                                  <p>Total Stars</p>
                                                  <p>
                                                    {`${level.chapter_groups[
                                                      group
                                                    ].chapters[chapter]
                                                      .chapter_stars ||
                                                      "-"} / ${Object.keys(
                                                        level.chapter_groups[group]
                                                          .chapters[chapter].lessons
                                                      ).length * 3}`}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className="lesson-container">
                                        {Object.keys(
                                          level.chapter_groups[group].chapters[
                                            chapter
                                          ].lessons
                                        ).map((lesson, i) => (
                                          <div
                                            key={i}
                                            className="lesson-card"
                                            style={{
                                              background: user_data?.levels?.[
                                                level.id
                                              ]?.chapter_groups?.[group]
                                                ?.chapters?.[chapter]
                                                ?.lessons?.[lesson]
                                                ? "#C6FCFF"
                                                : "#E8EFF0",
                                            }}
                                            onClick={() =>
                                              lessonClickHandler(
                                                level.chapter_groups[group]
                                                  .chapters[chapter].alias_name,
                                                level.chapter_groups[group]
                                                  .chapters[chapter].lessons[
                                                lesson
                                                ]
                                              )
                                            }
                                          >
                                            <div>
                                              <StarGenerator
                                                star_count={
                                                  level?.chapter_groups[group]
                                                    ?.chapters[chapter]
                                                    ?.lessons[lesson]
                                                    ?.lesson_stars
                                                }
                                              />
                                            </div>
                                            <div
                                              className="text"
                                              style={{ marginTop: "35px" }}
                                            >
                                              <p className="number">{i + 1}</p>
                                            </div>
                                            <div>
                                              <p className="title">
                                                {
                                                  level.chapter_groups[group]
                                                    .chapters[chapter].lessons[
                                                    lesson
                                                  ].name
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  );
                                })}
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
        <div
          style={{
            cursor: "pointer",
            position: "fixed",
            bottom: "45px",
            right: "50px",
          }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <span style={{ fontSize: "50px", color: "lightgrey" }}>
            {<IoIosArrowDropupCircle />}
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(HomePage);
