import { Achievements, LessonPage, TestPage, LoginPage } from "views";
import ForgotPassword from "views/authentication/ForgotPassword";
import ResetPassword from "views/authentication/ResetPassword";
import Signup from "views/authentication/Signup";
import UserProfile from "views/authentication/UserProfile";
import HomePage from "views/lessons/home/homePage";
import CreateTest from "views/tests/createTest";
import TestLeaderBoardPage from "views/tests/testLeaderBoardPage";

export const privateRoutes = [
  { path: "/competitions/create", component: CreateTest, exact: true },
  { path: "/user-profile", component: UserProfile, exact: true },
  { path: "/reset-password", component: ResetPassword, exact: true },
  { path: "/competitions/:id", component: LessonPage, exact: true }
];

export const publicRoutes = [
  { path: "/", component: HomePage, exact: true },
  { path: "/login", component: LoginPage, exact: true },
  { path: "/forgot-password", component: ForgotPassword, exact: true },
  { path: "/signup", component: Signup, exact: true },
  {
    path: "/chapters/:chapter_id/lessons/:id",
    component: LessonPage,
    exact: true,
  },
  { path: "/achievements", component: Achievements, exact: true },
  { path: "/competitions", component: TestPage, exact: true },
  { path: "/competitions/:id/leaderboard", component: TestLeaderBoardPage, exact: true}
];
