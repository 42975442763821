import React from "react";

const CustomInputField = ({
  inputName,
  inputType,
  inputValue,
  inputPlaceHolder,
  handleChange,
  icon,
  error,
  errorMessage,
}) => {
  return (
    <>
      <div className={`login-input-container ${error && "input-error"}`}>
        <span>
          <img src={icon} style={{ position: "absolute" }} alt="icon" />
        </span>
        <input
          name={inputName}
          type={inputType}
          placeholder={inputPlaceHolder}
          value={inputValue}
          style={{ marginLeft: "25px" }}
          onChange={handleChange}
          autoComplete="off"
        />
      </div>
      {error && errorMessage && (
        <div style={{ textAlign: "left", marginBottom: 25, marginTop: -10 }}>
          <p
            style={{
              color: "red",
              fontSize: "18px",
              fontFamily: "Nunito",
            }}
          >
            {errorMessage}
          </p>
        </div>
      )}
    </>
  );
};

export default CustomInputField;
