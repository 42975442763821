import React from "react";

const Characters = ({ data, styles }) => {
  return (
    <>
      {Object.keys(data?.characters).map((ite, i) => {
        let noUserReachCount = !data.characters[ite]?.["user_reach_count"];
        let userReachCount = data.characters[ite]?.["user_reach_count"];
        let actualReachCount = data.characters[ite]?.["reach_count"];
        let widthCalculation = userReachCount ? "100%" : "0%"

        return (
          <div
            key={i}
            style={
              noUserReachCount && actualReachCount
                ? styles.disableCard
                : styles.enableCard
            }
          >
            <div style={styles.insideCard}>
              <img
                src={
                  noUserReachCount && actualReachCount
                    ? data.characters[ite]["disable_image"]
                    : data.characters[ite]["enable_image"]
                }
                style={styles.cardImg}
                alt="icon"
              />
              <div
                style={{
                  backgroundColor: "#cfcfcf",
                  borderRadius: "3px",
                }}
              >
                <div className="progress">
                  <div
                    className="progress-inloader"
                    style={{
                      width: widthCalculation,
                      height: "1.5rem",
                      paddingTop: "4px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "10rem",
                        gap: 5,
                      }}
                    >
                      <span>
                        {data["users_characters"] >= actualReachCount
                          ? actualReachCount
                          : data["users_characters"] || 0}
                      </span>
                      <span>/</span>
                      <span>{actualReachCount}</span>
                      <span>Characters</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Characters;
