import React, { Component } from "react";
import "assets/css/Login.css";
import { Link } from "react-router-dom";
import emailIcon from "assets/images/svg/email-icon.svg";
import passwordIcon from "assets/images/svg/password-icon.svg";
import { login } from "api/authentication";
import LoginWithGoogle from "./LoginWithGoogle";
import { getCookie, setUserInfo } from "helpers";
import SubmitButton from "components/SubmitButton";
import CustomInputField from "components/CustomInputField";
import LoginDialog from "./LoginDialog";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      noEmailError: false,
      noPasswordError: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: "",
      noEmailError: false,
      noPasswordError: false,
    });
  };

  componentDidMount() {
    this.listener = document.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        this.handleSubmit();
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.listener);
  }

  handleSubmit = () => {
    const { username, password } = this.state;
    if (username && password) {
      login({ username: username, password: password })
        .then(({ data }) => {
          setUserInfo(data);
          window.location.href = getCookie("pervious_url") ? getCookie("pervious_url") : "/" 
        })
        .catch((err) => {
          this.setState({
            errorMessage: err[1] ? err[1].message : err.message,
          });
        });
    } else {
      this.setState({
        noEmailError: !username && true,
        noPasswordError: !password && true,
      });
    }
  };

  render() {
    const { handleChange, handleSubmit } = this;
    const {
      username,
      password,
      errorMessage,
      noEmailError,
      noPasswordError,
    } = this.state;
    return (
      <div
        className="login-form-container"
        style={{
          boxShadow: "2px 2px 8px 0px grey",
        }}
      >
        <p className="login-form-title">Login</p>
        <p className="login-helper-font">Enter your email and password</p>
        <div className="inputs-container">
          <CustomInputField
            inputName={"username"}
            inputType={"email"}
            inputValue={username}
            inputPlaceHolder={"Email ID"}
            handleChange={handleChange}
            icon={emailIcon}
            error={noEmailError}
            errorMessage={noEmailError && `Please enter a valid email`}
          />
          <CustomInputField
            inputName={"password"}
            inputType={"password"}
            inputValue={password}
            inputPlaceHolder={"Password"}
            handleChange={handleChange}
            icon={passwordIcon}
            error={noPasswordError}
            errorMessage={noPasswordError && `Please enter a valid password`}
          />
          {errorMessage && (
            <div style={{ textAlign: "left", marginBottom: "25px" }}>
              <p
                style={{
                  color: "red",
                  fontSize: "18px",
                  fontFamily: "Nunito",
                }}
              >
                {errorMessage === "Access Denied"
                  ? "Please enter valid username and password"
                  : errorMessage}
              </p>
            </div>
          )}
          <p
            style={{
              fontWeight: "500",
              cursor: "pointer",
              color: "black",
              textDecoration: "underline",
              textAlign: "left",
            }}
            className="login-bottom-font"
            onClick={() => {
              this.props.history.push("/forgot-password");
            }}
          >
            Forgot Password ?
          </p>
          <SubmitButton buttonText={"Login"} buttonClick={handleSubmit} />
        </div>
        <LoginWithGoogle />
        <div style={{ textAlign: "center" }}>
          <div>
            <p className="login-bottom-font">
              <span>Don't have an account?</span>
              <Link
                style={{
                  color: "red",
                  textDecoration: "none",
                  marginLeft: "10px",
                }}
                to="/signup"
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginDialog()(LoginPage);
