import { Header } from "components/Header";
import React, { Component } from "react";
import "assets/css/Profile.css";
import { getCookie } from "helpers";
import { resetPassword } from "api/authentication";
import SubmitButton from "components/SubmitButton";

const styles = {
  error: {
    position: "absolute",
    top: "70px",
    color: "red",
    fontSize: "18px",
    fontFamily: "Nunito",
  },
};
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      confirm_password: "",
      noMatchError: false,
      noNewPasswordError: false,
      noConfirmPasswordError: false,
      successMessage: "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: "",
      noMatchError: false,
      noNewPasswordError: false,
      noConfirmPasswordError: false,
    });
  };

  handleSubmit = () => {
    const { new_password, confirm_password } = this.state;
    if (!new_password || !confirm_password) {
      this.setState({
        noNewPasswordError: !new_password && true,
        noConfirmPasswordError: !confirm_password && true,
      });
      return;
    }

    if (new_password !== confirm_password) {
      this.setState({ noMatchError: true });
      return;
    }

    let requestBody = { password: confirm_password };
    let auth_token = getCookie("auth_token");
    resetPassword(requestBody, auth_token).then((res) => {
      this.setState({
        new_password: "",
        confirm_password: "",
        noMatchError: false,
        noNewPasswordError: false,
        noConfirmPasswordError: false,
        successMessage: res.data,
      });
    });
  };

  render() {
    const {
      new_password,
      confirm_password,
      noNewPasswordError,
      successMessage,
      noConfirmPasswordError,
      noMatchError,
    } = this.state;
    return (
      <div className="profile-background-image">
        <div id="container">
          <Header logoRedirect={"/"} />
          <div className="user-profile-container">
            <div
              className="profile-title-block"
              style={{ marginBottom: "90px" }}
            >
              <p className="title-font" style={{ marginBottom: "30px" }}>
                Change Password
              </p>
              <p className="title-description">
                You can update your account password here
              </p>
            </div>
            <div className="reset-form-block">
              <div className="reset-password-form-container">
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">NEW PASSWORD</p>
                    <input
                      type="password"
                      name="new_password"
                      value={new_password}
                      className="profile-input"
                      onChange={this.handleChange}
                    />
                  </div>
                  {noNewPasswordError && (
                    <p style={styles.error}>Please enter a new password</p>
                  )}
                </div>
                <div style={{ position: "relative" }}>
                  <div>
                    <p className="profile-label">CONFIRM PASSWORD</p>
                    <input
                      type="password"
                      name="confirm_password"
                      value={confirm_password}
                      className="profile-input"
                      onChange={this.handleChange}
                    />
                  </div>
                  {noConfirmPasswordError && (
                    <p style={styles.error}>Please re-enter password</p>
                  )}
                  {noMatchError && (
                    <p style={styles.error}>Passwords do not match</p>
                  )}
                  {successMessage && (
                    <p
                      style={{
                        position: "absolute",
                        top: "90px",
                        color: "green",
                        fontSize: "18px",
                        fontFamily: "Nunito",
                      }}
                    >
                      {successMessage}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <SubmitButton
                style={{ maxWidth: 300 }}
                buttonText="Change Password"
                buttonClick={this.handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
