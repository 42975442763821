import makeRequest from "./";

export function getTestListData(auth_token) {
  return makeRequest({
    uri: `/v1/tests`,
    authorization: `API_KEY ${auth_token}`,
  });
}

export function createPersonalTest(auth_token, requestBody) {
  return makeRequest({
    uri: `/v1/tests/users_test`,
    method: "POST",
    authorization: `API_KEY ${auth_token}`,
    body: JSON.stringify(requestBody),
  });
}

export function getOverallLeaderboard(auth_token) {
  return makeRequest({
    uri: `/v1/users/leader_board`,
    authorization: `API_KEY ${auth_token}`,
  });
}

export function getTestData(auth_token, test_id) {
  return makeRequest({
    uri: `/v1/tests/${test_id}`,
    authorization: `API_KEY ${auth_token}`,
  });
}

export function submitTest(auth_token, test_id, requestBody) {
  return makeRequest({
    uri: `/v1/tests/${test_id}/submit`,
    method: "POST",
    authorization: `API_KEY ${auth_token}`,
    body: JSON.stringify(requestBody),
  });
}

export function getTestLeaderboard(test_id) {
  return makeRequest({
    uri: `/v1/tests/${test_id}/leader_board`,
  });
}

export function getTestDetails(test_id) {
  return makeRequest({
    uri: `/v1/tests/${test_id}/counts`
  })
}
