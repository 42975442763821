/* global google */
import { signInWithGoogle } from "api/authentication";
import { getCookie, setUserInfo, tapSignInCookie } from "helpers";
import React, { useEffect } from "react";
import { googleLoginId } from "config";

const OneTapLogin = () => {
  const pathname = window.location.pathname;

  const whenSignedIn = (res) => {
    const requestBody = { id_token: res.credential };
    signInWithGoogle(requestBody).then(({ data }) => {
      setUserInfo(data);
      if (pathname.includes("login")) {
        window.location.href = "/";
        setTimeout(() => window.location.reload(), 2000);
      } else {
        setTimeout(() => window.location.reload(), 1000);
      }
    });
  };

  useEffect(() => {
    if (typeof google !== "undefined") {
      if (!getCookie("auth_token") && !getCookie("tapCookie")) {
        google && google.accounts && google.accounts.id.initialize({
          client_id: googleLoginId,
          callback: whenSignedIn,
        });
        google && google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed()) {
            console.log(
              notification.getNotDisplayedReason(),
              "Not displayed====>"
            );
          } else if (notification.isSkippedMoment()) {
            console.log(notification.getSkippedReason(), "skipped reason====>");
            tapSignInCookie();
          } else if (notification.isDismissedMoment()) {
            console.log(
              notification.getDismissedReason(),
              "dismissed reason====>"
            );
          }
        });
      }
    }
  }, []);

  return <div style={{ display: "none" }}></div>;
};

export default OneTapLogin;
