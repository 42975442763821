import React from "react";
import { Link } from "react-router-dom";
import Logo from "assets/images/svg/logo.svg";
import { getCookie, kickUser } from "helpers";
import ProfileImage from "assets/images/photos/profile.png";

const styles = {
  font: {
    fontFamily: "Source Sans Pro",
    fontWeight: "600",
  },
};

export const Header = ({ logoRedirect, login }) => {
  const first_name = getCookie("first_name");
  const profile_image = getCookie("profile_image");
  const auth_token = getCookie("auth_token");

  const handleLogout = () => {
    kickUser();
  };

  if (login) {
    return (
      <React.Fragment>
        <div className="top-bar">
          <Link to={logoRedirect}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="top-bar">
        <Link to={logoRedirect}>
          <img src={Logo} alt="logo" />
        </Link>
        <div style={{ display: "flex" }}>
          {first_name && (
            <div className="greeting-container">
              {`${getCookie("greet_user") || "Hello"} ${first_name} !`}
            </div>
          )}
          <div className="dropdown">
            {!auth_token ? (
              <img
                src={ProfileImage}
                style={{ borderRadius: "50%", width: 40, height: 40 }}
                alt="profile"
              />
            ) : profile_image !== null && profile_image !== "null" ? (
              <img
                src={profile_image}
                style={{ borderRadius: "50%", width: 40, height: 40 }}
                alt="profile"
              />
            ) : (
              <p
                className="profile-icon"
                style={{
                  backgroundColor: getCookie("profile_color") || "black",
                }}
              >
                {first_name && first_name[0]?.toUpperCase()}
              </p>
            )}
            <div style={{ marginTop: "20px" }}>
              <div className="dropdown-content">
                <div className="rectangle" />
                {!getCookie("auth_token") && (
                  <Link to="/login">
                    <p style={styles.font}>Login</p>
                  </Link>
                )}
                {getCookie("auth_token") && (
                  <>
                    {!window.location.pathname.includes("user-profile") && (
                      <Link to="/user-profile">
                        <p style={styles.font}>My Profile</p>
                      </Link>
                    )}
                    {!window.location.pathname.includes("reset-password") && (
                      <Link to="/reset-password">
                        <p style={styles.font}>Change Password</p>
                      </Link>
                    )}
                  </>
                )}
                {!window.location.pathname.includes("achievements") && (
                  <Link to="/achievements">
                    <p style={styles.font}>Achievements</p>
                  </Link>
                )}
                {!window.location.pathname.includes("competitions") && (
                  <Link to="/competitions">
                    <p style={styles.font}>Competitions</p>
                  </Link>
                )}
                {window.location.pathname !== "/" && (
                  <Link to="/">
                    <p style={styles.font}>Lessons</p>
                  </Link>
                )}
                {getCookie("auth_token") && (
                  <Link to="#" onClick={handleLogout}>
                    <p style={styles.font}>Logout</p>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
