import { Header } from "components/Header";
import React, { Component } from "react";
import "assets/css/test/Test.css";
import emoji from "assets/images/svg/emoji.svg";
import girl from "assets/images/svg/girl.svg";
import testAccuracy from "assets/images/svg/test-accuracy.svg";
import testClock from "assets/images/svg/test-clock.svg";
import CustomButton from "components/CustomButton";
import { getTestLeaderboard } from "api/testing";
import { Link } from "react-router-dom";
import testSpeedometer from "assets/images/svg/test-speedometer.svg";
import emptyLeader from "assets/images/svg/leaderboard.svg";
class TestSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      test_leaderboard_data: [],
      copied: false,
      empty: true
    };
  }

  componentDidMount() {
    this.fetchTestLeaderboard();
  }

  groupBy = (arrayValue, key) => {
    return arrayValue.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  fetchTestLeaderboard = async () => {
    let test_id = this.props.testId;
    await getTestLeaderboard(test_id).then(({ data }) => {
      let groupData = {}
      groupData = this.groupBy(data, 'user_id')
      let final_array = Object.keys(groupData).map((key) => {
        // let val = Math.max.apply(Math, groupData[key].map(function (o) { return o.score; }))
        return groupData[key] && groupData[key][0]
      })
      this.setState({
        test_leaderboard_data: final_array,
        empty: false
      })
    }

    );
  };

  copyClickHandler = () => {
    this.setState({ copied: true });
  };

  render() {
    const { test_leaderboard_data, empty } = this.state;
    const { accuracy, duration, wpm } = this.props;
    return (
      <div className="background-image">
        <div
          style={{
            width: "100%",
            maxWidth: "1440px",
            margin: "auto",
          }}
        >
          <Header logoRedirect={"/competitions"} />
          <div className="test-success-center-card"
            style={{
              width: "80%",
              margin: "0 auto",
              display: "flex",
              gap: "25px",
            }}
          >
            <div style={{ flex: "0.8" }}>
              <div className="test-success-left-top-card">
                <div style={{ position: "relative" }}>
                  <span className="success-left-top-title">
                    Awesome! Test Completed
                  </span>
                  <img
                    src={emoji}
                    style={{ position: "absolute", top: "5px" }}
                    alt="emoji"
                  />
                </div>
                <div style={{ display: "flex", gap: "40px" }}>
                  <div style={{flex: "1"}}>
                    <img
                      src={girl}
                      style={{ width: "169px", height: "366px" }}
                      alt="girl"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "column",
                      flex: "1",
                    }}
                  >
                    <div>
                      <p><img src={testSpeedometer} /><span className="success-left-top-stats-title">Speed</span></p>
                      <p><span className="success-left-top-stats-value">{wpm}</span> <span className="success-left-top-stats-unit">WPM</span></p>
                    </div>
                    <div >
                      <p>
                        <img src={testAccuracy} alt="accuracy-icon" />
                        <span className="success-left-top-stats-title">
                          Accuracy
                        </span>
                      </p>
                      <p>
                        <span className="success-left-top-stats-value">
                          {accuracy}
                        </span>{" "}
                        <span className="success-left-top-stats-unit">%</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        <img src={testClock} alt="clock-icon" />
                        <span className="success-left-top-stats-title">
                          Time
                        </span>
                      </p>
                      <p>
                        <span className="success-left-top-stats-value">
                          {duration}
                        </span>{" "}
                        <span className="success-left-top-stats-unit">
                          seconds
                        </span>
                      </p>
                    </div>

                  </div>
                </div>
                {accuracy < 60 && <p className="success-accuracy-title">Improve your accuracy to take part in the leaderboard</p>}
                <div style={{ display: "flex", gap: "25px" }}>
                  <Link to="/competitions" style={{flex: "1"}}>
                    <CustomButton
                      buttonText={"Home"}
                      style={{
                        height: "50px",
                        width: "100%",
                        background:
                          "linear-gradient(#d8f5a2, #3e9c75)",
                        borderRadius: "46px",
                        fontFamily: "Nunito",
                        fontStyle: "normal",
                        fontWeight: 800,
                        fontSize: "22px",
                        lineHeight: "30px",
                        textAlign: "center",
                        letterSpacing: "0.02em",
                        color: "#FFFFFF",
                        border: "none",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <CustomButton
                    buttonClick={() => window.location.reload()}
                    buttonText={"Replay"}
                    style={{
                      height: "50px",
                      flex: "1",
                      background:
                        "linear-gradient(#d8f5a2, #3e9c75)",
                      borderRadius: "46px",
                      fontFamily: "Nunito",
                      fontStyle: "normal",
                      fontWeight: 800,
                      fontSize: "22px",
                      lineHeight: "30px",
                      textAlign: "center",
                      letterSpacing: "0.02em",
                      color: "#FFFFFF",
                      border: "none",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="test-success-right-card" style={{ flex: "1.2" }}>
              <div>
                <p className="test-success-right-title">Leaderboard</p>
              </div>
              {!empty && test_leaderboard_data.length !== 0 ?
              <table
                style={{
                  borderSpacing: "10px 20px",
                  width: "100%",
                }}
              >
                <tr className="test-success-column-name">
                  <th>Name</th>
                  <th>Speed</th>
                  <th>Accuracy</th>
                  <th>Points</th>
                </tr>
                {test_leaderboard_data?.map((record, i) => {
                  if (i <= 20) {
                    return (
                      <tr key={i} className="test-success-column-value">
                        <td>{record.name > 19 ? `${record?.name.slice(0, 19)}..` : record?.name}<br /><span style={{fontSize: "16px", color: "#aa90f5"}}>{record?.school_name.length > 40 ? `${record?.school_name.slice(0, 40)}...` : record?.school_name}</span></td>
                        <td>{`${record.speed} WPM`}</td>
                        <td>{`${record.accuracy} %`}</td>
                        <td>{`${record.score} pts.`}</td>
                      </tr>
                    );
                  }
                })}
              </table> : empty ? <></> : <div style={{height: "100%",display: "flex",alignItems: "center",justifyContent: "center"}}>
                    <img style={{width: "80%"}} src={emptyLeader} alt="leader" />
                </div> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestSuccessPage;
