import React from "react";
import star from "assets/images/svg/star.svg";
import dimStar from "assets/images/svg/dim-star.svg";

const StarGenerator = ({ star_count }) => {
const firstStarStyle = {
    position: "absolute",
    left: "30px",
    top: "35px",
  };

  const secondStarStyle = {
    position: "absolute",
    left: "60px",
  };

  const thirdStarStyle = {
    position: "absolute",
    right: "30px",
    top: "35px",
  };
  return (
    <>
      <img
        style={firstStarStyle}
        src={star_count > 0 ? star : dimStar}
        alt="star"
      />
      <img
        style={secondStarStyle}
        src={star_count > 1 ? star : dimStar}
        alt="star"
      />
      <img
        style={thirdStarStyle}
        src={star_count > 2 ? star : dimStar}
        alt="star"
      />
    </>
  );
};

export default StarGenerator;
