import React from "react";
import { signInWithGoogle } from "api/authentication";
import { googleLoginId } from "config";
import { getCookie, setUserInfo } from "helpers";
import { GoogleLogin } from "react-google-login";

//"googleLoginId": "1012009884652-2aqt28a5nuu1vdi1lop6r6genuq7fotf.apps.googleusercontent.com" // Stage ID
//"237362739372-k531c9jsr5t5jpsr0gvh0lkbjulgvl11.apps.googleusercontent.com" // Praveen's ID

function LoginWithGoogle() {
  const onSuccess = (res) => {
    const requestBody = { id_token: res.tokenId };
    let user_id = getCookie("user_id");
    if (user_id) {
      requestBody["user_id"] = user_id;
    }
    signInWithGoogle(requestBody).then(({ data }) => {
      setUserInfo(data);
      window.location.href = getCookie("pervious_url") ? getCookie("pervious_url") : "/"
    });
  };

  const onFailure = (e) => {
    console.log(e, "failed google sign in");
    // this.props.history.push("/login")
    // window.location.pathname = "login"
  };

  return (
    <GoogleLogin
      className="google-signin-button"
      clientId={googleLoginId}
      buttonText={"Login with Google"}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
      isSignedIn={false}
    />
  );
}

export default LoginWithGoogle;
