import React from "react";
import emoji from "assets/images/svg/emoji.svg";
import enableStar from "assets/images/svg/Group.svg";
import disableStar from "assets/images/svg/unsuccess.svg";
import restart from "assets/images/svg/restart.svg";
import { Link } from "react-router-dom";
import sucessBG from "assets/images/photos/success_new.png";
import { ProgressBar } from "components/ProgressBar";
import lottieAnimation from 'assets/lottie/lottie_animation.json';
import Lottie from 'react-lottie';
import { TiHome } from "react-icons/ti";
import { NextIcon } from "assets/images/svg/nextIcon";

export const SuccessPage = ({
  chapter_id,
  wpm,
  accuracy,
  wrongChar,
  handleRetry,
  next_lesson,
  current_lesson,
  rightChar,
  total_lessons
}) => {
  const star = accuracy > 95 ? 3 : accuracy > 80 ? 2 : 1;
  const content = {
    1: ["Keep doing it", "Not Bad", "Need to improve", "Keep Practicing"],
    2: ["Keep it up!", "Way to go", "You can do it!", "Nice Work"],
    3: ["Awesome!", "Fantastic", "Marvelous", "Great Job", "First Class"],
  };

  const totalChars = rightChar + wrongChar;
  const totalPer = ((wrongChar * 100) / totalChars)
  var myArray = content[star];
  var randomItem = myArray[Math.floor(Math.random() * myArray.length)];
  const present_lesson = current_lesson?.split("_")[1]
  const options = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const nextLessonHandler = () => {
    window.location.href = `/chapters/${chapter_id}/lessons/${next_lesson}`;
  };
  document.addEventListener("keydown", (e) => {
    if (e.keyCode === 13) {
      if (!next_lesson) {
        window.location.href = "/";
      } else {
        nextLessonHandler();
      }
    }
  });

  return (
    <div className="main">
      <div
        className="popupbackground"
        style={{
          backgroundImage: `url("${sucessBG}")`,
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "1440px",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignContent: "space-between",
              height: "100vh",
              justifyContent: "center",
              color: "white",
              alignItems: "center",
            }}
          >
            <Lottie
              style={{ position: "absolute", top: "0px", left: "0px" }}
              options={options}
              isClickToPauseDisabled={true}
              isPaused={false}
              isStopped={false}
            />
            <div className="group2">
              <div className="group">
                <div
                  onClick={handleRetry}
                  className="success-refresh-btn"
                >
                  <img src={restart} alt="enable_star" />
                  Retry
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "800",
                    fontFamily: "Nunito",
                  }}
                >
                  <h1 style={{ margin: "0px 44px" }}>{randomItem}</h1>
                  <img style={{ width: "44px" }} src={emoji} alt="" />
                </div>
                <div className="success-star-container">
                  {star === 3 ? (
                    <>
                      <img
                        src={enableStar}
                        className="successStar"
                        id="firstStar"
                        alt="enable_star"
                      />
                      <img
                        src={enableStar}
                        className="successStar"
                        alt="enable_star"
                        id="topStar"
                      />
                      <img
                        src={enableStar}
                        className="successStar"
                        id="lastStar"
                        alt="enable_star"
                      />
                    </>
                  ) : star === 2 ? (
                    <>
                      <img
                        src={enableStar}
                        className="successStar"
                        id="firstStar"
                        alt="enable_star"
                      />
                      <img
                        src={enableStar}
                        className="successStar"
                        id="topStar"
                        alt="enable_star"
                      />
                      <img
                        src={disableStar}
                        className="successStar"
                        id="lastStar"
                        alt="enable_star"
                      />
                    </>
                  ) : star === 1 ? (
                    <>
                      <img
                        src={enableStar}
                        className="successStar"
                        id="firstStar"
                        alt="enable_star"
                      />
                      <img
                        src={disableStar}
                        className="successStar"
                        id="topStar"
                        alt="enable_star"
                      />
                      <img
                        src={disableStar}
                        className="successStar"
                        id="lastStar"
                        alt="enable_star"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={disableStar}
                        className="successStar"
                        id="firstStar"
                        alt="enable_star"
                      />
                      <img
                        src={disableStar}
                        className="successStar"
                        id="topStar"
                        alt="enable_star"
                      />
                      <img
                        src={disableStar}
                        className="successStar"
                        id="lastStar"
                        alt="enable_star"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="progress-whole-container">
                <ProgressBar title={'Speed'} value={wpm + " WPM"} percentage={wpm > 100 ? 0.5 : ((wpm / 100) / 2)} style={'#E5A260'} />
                <ProgressBar title={'Accuarcy'} value={accuracy + "%"} percentage={((accuracy / 100) / 2)} style={'#6BC882'} />
                <ProgressBar title={'Typos'} value={wrongChar} percentage={((totalPer / 100) / 2)} style={'#FF6F98'} />
              </div>
              <div>
                <div className="bar">
                  <div className="progress-line">
                    <span style={{ width: `${(present_lesson / total_lessons) * 100}%` }}></span>
                    <div className="progress-content" style={{ left: `${(present_lesson / total_lessons) * 100}%` }}>
                      <p className="progress-content-title">Lesson</p>
                      <p className="progress-content-desc">{present_lesson}/{total_lessons}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="successpage-btn">
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <button
                    className="lesson-success-btn"
                  ><TiHome style={{ fontSize: "25px" }} /><span>Home</span>
                  </button></Link>
                {next_lesson && (
                  <button
                    className="lesson-next-btn"
                    onClick={nextLessonHandler}
                    type="submit"
                  ><span>Next</span><NextIcon /></button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
